import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { ViewContainer } from "../../components/ViewContainer";
import { isSwedishVersion } from "../../utils/location";
import { retrieveLanguage } from "../../utils/languageStore";
import { ASSETS_HOST } from "../../constants/urls";

type GuidesMapping = {
    default: string;
    se: string;
    sv: string;
    [key: string]: string;
};

export default function Guides() {
    const lang = retrieveLanguage();

    const guidesMapping: GuidesMapping = {
        default: "/documents/hydrolink_fi_online_guide_fi.pdf",
        se: "/documents/hydrolink_se_online_guide_sv.pdf",
        sv: "/documents/hydrolink_fi_online_guide_sv.pdf",
    };

    const getGuidesDocument = (): string => {
        const language = isSwedishVersion() ? "se" : lang;
        const guidePath = guidesMapping[language] || guidesMapping["default"];
        // Use an absolute path if ASSETS_HOST is defined, otherwise use a relative path
        if (ASSETS_HOST !== undefined) {
            return `${ASSETS_HOST}${guidePath}`;
        }
        return guidePath;
    };

    return elementWithHeaderAndFooter(
        <ViewContainer>
            <iframe
                title="Manual"
                style={{
                    minHeight: "calc(100vh - 150px)",
                    width: "100%",
                    marginBottom: "20px",
                    border: "0px",
                }}
                src={getGuidesDocument()}
            ></iframe>
        </ViewContainer>,
    );
}
