import { Company, Role } from "../../../../redux/companies/companies-types";
import ManagerRow from "./ManagerRow";
import { User } from "../../../../redux/users/users-types";
import { getColor } from "./Managers";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import ManagerControls from "./ManagerControls";
import AddUserAsAdmin from "./AddUserAsAdmin";
import { CompanyUserWithState } from "./listEditor";

type Props = {
    company: Company;
    users: User[];
    editedManagers: CompanyUserWithState[];
    addUser: (id: number, role: Role) => void;
    changeRole: (userId: number, role: Role) => void;
    deleteManager: (managerId: number) => void;
    makePrincipal: (userId: number) => void;
};

const AdminView = ({ company, users, editedManagers, addUser, changeRole, deleteManager, makePrincipal }: Props) => {
    const canSetPrincipalManager = useHasPrivileges("can set principal manager", company.id).hasPrivilege;

    return (
        <>
            {editedManagers.map((manager) => (
                <ManagerRow
                    key={manager.user.userId + manager.state.toBeCreated.toString()}
                    id={manager.user.userId}
                    firstName={manager.user.firstName}
                    lastName={manager.user.lastName}
                    color={getColor(manager.state)}
                    delete={deleteManager}
                    companyId={company.id}
                    email={manager.user.email}
                >
                    {canSetPrincipalManager ? (
                        <ManagerControls
                            key={manager.user.userId}
                            manager={manager.user}
                            changeRole={changeRole}
                            makePrincipal={makePrincipal}
                        />
                    ) : null}
                </ManagerRow>
            ))}
            <AddUserAsAdmin company={company} allUsers={users} editedManagers={editedManagers} addUser={addUser} />
        </>
    );
};
export default AdminView;
