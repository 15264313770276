import { useTranslation } from "react-i18next";
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import { Button } from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../../interfaces/RootState";
import { ManageCompanyUsersAction } from "../../../../redux/companies/companies-actions";
import { Company, Role, Manager, ManageCompanyUsersPayload } from "../../../../redux/companies/companies-types";
import { colors, table } from "../../../../theme";
import { User } from "../../../../redux/users/users-types";
import CreateUserModal from "../../../users/CreateUserModal";
import FloatButtonsRight from "../../../../components/FloatButtonsRight";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import AdminView from "./AdminView";
import ManagerAndViewerViews from "./ManagerAndViewerViews";
import { FetchUsersAction, SortUsersAction } from "../../../../redux/users/users-actions";
import { CompanyUserWithState, EditEvent, EditState, withEdits } from "./listEditor";
import { CompanyUser } from "./CompanyUser";
import CreateUserButton from "../../../users/CreateUserButton";
import Sorter from "../../../../components/Sorter";
import useSortedAndFiltered from "../../../../utils/sortAndFilter";

export type SaveType = "create" | "update";

//New users of this type are users which somebody with the manager role may add. Admins adds users from the global user list instead.
export type NewUser = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
};

const actionColors = {
    neutralColor: colors.neutral00,
    addColor: colors.success06,
    removeColor: colors.warm06,
};

export const getManagersCopy = (companyManagers: Manager[]): Manager[] => {
    return JSON.parse(JSON.stringify(companyManagers));
};

export const sortManagerById = (companyManager: Manager[]) => {
    let m = getManagersCopy(companyManager);
    m.sort(function (a, b) {
        return a.id - b.id;
    });
    return m;
};

export const getColor = (managerState: EditState): string => {
    if (managerState.toBeDeleted) {
        return actionColors.removeColor;
    } else if (managerState.toBeCreated || managerState.toBeUpdated) {
        return actionColors.addColor;
    }
    return actionColors.neutralColor;
};

const saveFunction = (companyId: number, dispatch: Dispatch<any>, editedManagers: CompanyUserWithState[]) => {
    const payload: ManageCompanyUsersPayload = {
        companyId: companyId,
        toCreate: [],
        toUpdate: [],
        toDelete: [],
    };
    for (let m of editedManagers) {
        if (m.state.toBeCreated) {
            payload.toCreate.push({
                email: m.user.email,
                firstName: m.user.firstName,
                lastName: m.user.lastName,
                role: m.user.role,
                principal: m.user.principal,
            });
        } else if (m.state.toBeUpdated) {
            payload.toUpdate.push({
                userId: m.user.userId,
                role: m.user.role,
                principal: m.user.principal,
            });
        }
        if (m.state.toBeDeleted) {
            payload.toDelete.push(m.user.userId);
        }
    }
    dispatch(ManageCompanyUsersAction(payload));
};

function withUserInfo(users: User[], manager: Manager): CompanyUser | undefined {
    const user = users.find((user) => user.id === manager.userId);
    if (user === undefined) {
        return undefined;
    }

    return {
        userId: manager.userId,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: manager.role,
        principal: manager.principal,
    };
}

const getSortedManagersData = (currentSorting: string) => {
    switch (currentSorting) {
        case "firstName":
            return (manager: CompanyUserWithState) => manager.user.firstName;
        case "lastName":
            return (manager: CompanyUserWithState) => manager.user.lastName;
        case "email":
            return (manager: CompanyUserWithState) => manager.user.email;
    }
};

function ManagersTableHead(): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const currentSorting = useSelector((state: RootState) => state.hydrolink.users.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.users.ascending);

    const sortManagersFunction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortUsersAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    return (
        <TableHead>
            <TableRow>
                <Sorter
                    sortKey={"firstName"}
                    sortAction={sortManagersFunction}
                    sortBy={currentSorting}
                    title={t("users.firstName")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"lastName"}
                    sortAction={sortManagersFunction}
                    sortBy={currentSorting}
                    title={t("users.lastName")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"email"}
                    sortAction={sortManagersFunction}
                    sortBy={currentSorting}
                    title={t("users.email")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <TableCell>{t("users.role")}</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
}

export default function Managers({ company }: { company: Company }): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const managers = useSelector(
        (state: RootState) => state.hydrolink.companies.companies.find((c) => c.id === company.id)?.managers,
    );
    const users = useSelector((state: RootState) => state.hydrolink.users.users);
    const companyManagers: CompanyUser[] = useMemo(() => {
        const sorted = sortManagerById(managers ?? []);
        return sorted.flatMap((m) => withUserInfo(users, m) ?? []);
    }, [managers, users]);
    const companies = useSelector((state: RootState) => state.hydrolink.companies);
    const currentSorting = useSelector((state: RootState) => state.hydrolink.users.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.users.ascending);

    const isAllowedToAddUsers = useHasPrivileges("is allowed to add users to the company", company.id).hasPrivilege;
    const asAdminCanAddCompanyUser = useHasPrivileges("as an admin can add company user", company.id).hasPrivilege;

    const canSeeCreateUserButton = useHasPrivileges(
        "can see create user button in the managers block",
        company.id,
    ).hasPrivilege;

    useEffect(() => {
        dispatch(FetchUsersAction());
    }, [companies, dispatch]);

    useEffect(() => setEdits([]), [managers]);

    const [edits, setEdits] = useState<EditEvent[]>([]);

    function addEdit(edit: EditEvent) {
        setEdits((edits) => edits.concat(edit));
    }

    function addUserFromList(userId: number, role: Role) {
        addEdit({ event: "added-from-list", userId, role });
    }

    function addNewUser(newUser: NewUser) {
        addEdit({
            event: "added-new",
            userId: newUser.id,
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            role: newUser.role,
        });
    }

    function deleteUser(userId: number) {
        addEdit({ event: "delete-toggled", userId });
    }

    function changeRole(userId: number, role: Role) {
        addEdit({ event: "role-changed", userId, role });
    }

    function makePrincipal(userId: number) {
        addEdit({ event: "made-principal", userId });
    }

    const editedManagers = useMemo(
        () => withEdits(users, companyManagers ?? [], edits),
        [edits, companyManagers, users],
    );

    const anythingChanged = () =>
        editedManagers.some((cu) => cu.state.toBeCreated || cu.state.toBeUpdated || cu.state.toBeDeleted);

    const save = () => saveFunction(company.id, dispatch, editedManagers);

    const sortedAndFilteredManagers = useSortedAndFiltered(
        editedManagers,
        getSortedManagersData(currentSorting),
        currentAscending,
    );

    return (
        <div style={{ width: "100%" }}>
            <CreateUserModal />
            <TableContainer component="div">
                <Table aria-label="simple table">
                    <ManagersTableHead />
                    <TableBody>
                        {asAdminCanAddCompanyUser ? (
                            <AdminView
                                company={company}
                                addUser={addUserFromList}
                                deleteManager={deleteUser}
                                editedManagers={sortedAndFilteredManagers.items}
                                users={users}
                                changeRole={changeRole}
                                makePrincipal={makePrincipal}
                            />
                        ) : (
                            <ManagerAndViewerViews
                                company={company}
                                addUser={addNewUser}
                                deleteManager={deleteUser}
                                editedManagers={sortedAndFilteredManagers.items}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {isAllowedToAddUsers && (
                <FloatButtonsRight
                    buttons={[
                        ...(canSeeCreateUserButton ? [<CreateUserButton withAdminCheckbox={false} />] : []),
                        <Button variant="contained" disabled={!anythingChanged()} onClick={save}>
                            {t("generic.save")}
                        </Button>,
                    ]}
                />
            )}
        </div>
    );
}
