import { Button } from "../../components/Button";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { useTranslation } from "react-i18next";
import { alignments, borders, colors, spacings, typography } from "../../theme";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import TOC_fi from "./TOC_fi.md";
import TOC_sv from "./TOC_sv.md";

const containerStyle = {
    ...alignments.centered,
    padding: `0 ${spacings.standardSpacing} 0 ${spacings.standardSpacing}`,
};

const textAreaStyle = {
    ...borders.modalTextAreaBorder,
    display: "block",
    fontSize: typography.fontSize.default,
    backgroundColor: colors.neutral04,
    color: colors.neutral08,
    width: "680px",
    overflow: "auto",
    padding: `${spacings.narrow} ${spacings.standardSpacing} ${spacings.narrow} ${spacings.standardSpacing}`,
};

function TermsAndConditionsContainer(): JSX.Element {
    const t = useTranslation().t;
    const language = useTranslation().i18n.language;
    const navigate = useNavigate();
    const path = "/companies";

    const [doc, setDoc] = useState("");

    useEffect(() => {
        const doc = language === "sv" ? TOC_sv : TOC_fi;
        fetch(doc)
            .then((res) => res.text())
            .then((text) => setDoc(text));
    }, [language]);

    const closeModal = (): void => {
        navigate(path, { replace: true });
    };

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <div style={containerStyle}>
                <form>
                    <div
                        style={{
                            paddingBottom: spacings.standardSpacing,
                            fontSize: typography.fontSize.modalTitle,
                            textAlign: "center",
                            marginTop: spacings.standardSpacing,
                        }}
                    >
                        {t("termsAndConditions.title")}
                    </div>
                    <div
                        style={{
                            paddingBottom: spacings.standardSpacing,
                            textAlign: "center",
                            overflow: "hidden",
                        }}
                    >
                        {" "}
                        <br></br>
                        <div
                            style={{
                                ...textAreaStyle,
                                textAlign: "left",
                                textJustify: "inter-word",
                            }}
                        >
                            <ReactMarkdown children={doc} />
                        </div>
                    </div>

                    <div
                        style={{
                            textAlign: "right",
                            marginTop: spacings.standardSpacing,
                            marginBottom: spacings.standardSpacing,
                        }}
                    >
                        <Button variant="outlined" onClick={closeModal}>
                            {t("termsAndConditions.ok")}
                        </Button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

export default function ViewTermsAndConditions(): JSX.Element {
    return elementWithHeaderAndFooter(<TermsAndConditionsContainer />);
}
