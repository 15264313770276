import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractState, Contract } from "../../redux/contracts/contracts-types";
import { CreateUserDpaModal } from "./CreateUserDpaModal";
import { CreateCompanyDpaModal } from "./CreateCompanyDpaModal";
import { colors, spacings } from "../../theme";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeadCell,
    TableRow,
} from "../../components/Table";
import { TFunction } from "i18next";
import { Button } from "../../components/Button";
import { useAppSelector } from "../../utils/store-helpers";
import { ViewContainer } from "../../components/ViewContainer";
import { useDispatch } from "react-redux";
import { FetchUserContractsAction } from "../../redux/contracts/contracts-actions";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import { CreateCompanySubscriptionModal } from "./CreateCompanySubscriptionModal";
import SendSignRequestButton from "./SendSignRequestButton";
import { Block } from "../../components/common/block";

function translatedContractState(t: TFunction<"translation", undefined>, state: ContractState): string {
    switch (state) {
        case ContractState.UNKNOWN:
            return t("contracts.state.UNKNOWN");
        case ContractState.PENDING:
            return t("contracts.state.PENDING");
        case ContractState.CANCELLED:
            return t("contracts.state.CANCELLED");
        case ContractState.ACTIVE:
            return t("contracts.state.ACTIVE");
    }
}

function contractStateColor(state: ContractState): React.CSSProperties {
    switch (state) {
        case ContractState.ACTIVE:
            return { color: colors.success06 };
        case ContractState.CANCELLED:
            return { color: colors.rowColorRemove };
        case ContractState.PENDING:
            return { color: colors.rowColorRemove };
    }
    return {};
}

function contractStateInterestingness(s: ContractState): number {
    switch (s) {
        case ContractState.PENDING: // most interesting
            return 0;
        case ContractState.ACTIVE:
            return -1;
        case ContractState.CANCELLED:
            return -2;
        case ContractState.UNKNOWN: // least interesting
            return -3;
    }
}

function compareContracts(a: Contract, b: Contract): number {
    const aInterest = contractStateInterestingness(a.state);
    const bInterest = contractStateInterestingness(b.state);
    if (aInterest !== bInterest) {
        return aInterest - bInterest;
    }
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
}

type ContractTableProps = {
    title: string;
    contracts: Contract[];
    canCreate: boolean;
    onCreateClicked: () => void;
};

function ContractTable({ title, contracts, canCreate, onCreateClicked }: ContractTableProps): JSX.Element {
    const t = useTranslation().t;
    return (
        <div style={{ textAlign: "center", minWidth: "550px" }}>
            <h3>{title}</h3>
            <Block variant="white">
                <TableContainer component="div">
                    {contracts.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{t("contracts.table.header.provider")}</TableHeadCell>
                                    <TableHeadCell>{t("contracts.table.header.id")}</TableHeadCell>
                                    <TableHeadCell>{t("contracts.table.header.state")}</TableHeadCell>
                                    <TableHeadCell>{t("contracts.table.header.updatedAt")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contracts.map((contract) => (
                                    <TableRow key={contract.id}>
                                        <TableCell>{contract.providerName}</TableCell>
                                        <TableCell>{contract.providerContractId}</TableCell>
                                        <TableCell style={contractStateColor(contract.state)}>
                                            {translatedContractState(t, contract.state)}
                                        </TableCell>
                                        <TableCell>{new Date(contract.updatedAt).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <SendSignRequestButton
                                                contractId={contract.id}
                                                contractState={contract.state}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                    <div style={{ marginTop: spacings.standardMargin }}>
                        <Button disabled={!canCreate} onClick={onCreateClicked}>
                            {t("contracts.buttons.create")}
                        </Button>
                    </div>
                </TableContainer>
            </Block>
        </div>
    );
}

export type Props = {
    userId: number;
};

export default function ContractsView({ userId }: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId !== 0) {
            dispatch(FetchUserContractsAction({ userId }));
            dispatch(FetchCompaniesWithoutAlarmsAction());
        }
    }, [dispatch, userId]);

    const companies = useAppSelector((state) => state.hydrolink.companies.companies);
    const principallyManagedCompanies = useMemo(
        () =>
            companies
                .filter((company) =>
                    company.managers.some(
                        (manager) => manager.userId === userId && manager.role === "manager" && manager.principal,
                    ),
                )
                .sort((a, b) => a.name.localeCompare(b.name)),
        [companies, userId],
    );

    const [isUserDpaModalOpen, setIsUserDpaModalOpen] = useState(false);
    const [isCompanySubscriptionModalOpenById, setIsCompanySubscriptionModalOpenById] = useState<
        Record<number, boolean>
    >({});
    const [isCompanyDpaModalOpenById, setIsCompanyDpaModalOpenById] = useState<Record<number, boolean>>({});

    function onOpenUserDpaModalClicked() {
        setIsUserDpaModalOpen(true);
    }
    function onCloseUserDpaModalClicked() {
        setIsUserDpaModalOpen(false);
    }
    function onOpenCompanySubscriptionModalClicked(companyId: number) {
        return () => setIsCompanySubscriptionModalOpenById((prev) => ({ ...prev, [companyId]: true }));
    }
    function onCloseCompanySubscriptionModalClicked(companyId: number) {
        return () => setIsCompanySubscriptionModalOpenById((prev) => ({ ...prev, [companyId]: false }));
    }
    function onOpenCompanyDpaModalClicked(companyId: number) {
        return () => setIsCompanyDpaModalOpenById((prev) => ({ ...prev, [companyId]: true }));
    }
    function onCloseCompanyDpaModalClicked(companyId: number) {
        return () => setIsCompanyDpaModalOpenById((prev) => ({ ...prev, [companyId]: false }));
    }

    const userContracts = useAppSelector((state) => state.hydrolink.contracts.userContractsByUserId);
    const userDpaContracts = useMemo(() => userContracts[userId]?.userDpaContracts ?? [], [userContracts, userId]);
    const companySubscriptionContractsByCompanyId = useMemo(
        () => userContracts[userId]?.companySubscriptionContractsByCompanyId ?? {},
        [userContracts, userId],
    );
    const companyDpaContractsByCompanyId = useMemo(
        () => userContracts[userId]?.companyDpaContractsByCompanyId ?? {},
        [userContracts, userId],
    );

    const sortedUserDpas = useMemo(
        () => [...userDpaContracts].sort((a, b) => compareContracts(b, a)),
        [userDpaContracts],
    );

    const sortedCompanyContracts = useMemo(
        () =>
            principallyManagedCompanies.map((company) => ({
                company,
                subscriptions: [...(companySubscriptionContractsByCompanyId[company.id] ?? [])].sort((a, b) =>
                    compareContracts(b, a),
                ),
                hasActiveSubscription: (companySubscriptionContractsByCompanyId[company.id] ?? []).some(
                    (contract) => contract.state === ContractState.ACTIVE,
                ),
                dpas: [...(companyDpaContractsByCompanyId[company.id] ?? [])].sort((a, b) => compareContracts(b, a)),
            })),
        [principallyManagedCompanies, companySubscriptionContractsByCompanyId, companyDpaContractsByCompanyId],
    );
    function companyHasActiveSubscription(companyId: number) {
        return sortedCompanyContracts.some(
            (companyContracts) => companyContracts.company.id === companyId && companyContracts.hasActiveSubscription,
        );
    }
    const someCompanyHasActiveSubscription = useMemo(
        () => sortedCompanyContracts.some((companyContracts) => companyContracts.hasActiveSubscription),
        [sortedCompanyContracts],
    );

    return (
        <ViewContainer>
            <Block variant="grey">
                {sortedCompanyContracts.map(({ company, subscriptions }) => (
                    <div key={`companySubscriptions${company.id}`}>
                        <CreateCompanySubscriptionModal
                            isOpen={isCompanySubscriptionModalOpenById[company.id] ?? false}
                            close={onCloseCompanySubscriptionModalClicked(company.id)}
                            userId={userId}
                            companyId={company.id}
                            initialData={{
                                housingCompanyBusinessId: "",
                                housingCompanyStreetAddress: company.streetAddress,
                                housingCompanyZipCode: company.zipCode,
                                housingCompanyTown: company.town,
                                managementCompanyName: "",
                                managementCompanyBusinessId: "",
                                managementCompanyStreetAddress: "",
                                managementCompanyZipCode: "",
                                managementCompanyTown: "",
                                managerPhone: "",
                            }}
                        />
                        <ContractTable
                            title={`${t("contracts.sections.companySubscriptions")} (${company.name})`}
                            contracts={subscriptions}
                            canCreate={true}
                            onCreateClicked={onOpenCompanySubscriptionModalClicked(company.id)}
                        />
                    </div>
                ))}
                {sortedCompanyContracts.map(({ company, dpas }) => (
                    <div key={`companyDpas${company.id}`}>
                        <CreateCompanyDpaModal
                            isOpen={isCompanyDpaModalOpenById[company.id] ?? false}
                            close={onCloseCompanyDpaModalClicked(company.id)}
                            userId={userId}
                            companyId={company.id}
                            initialData={{
                                businessId: "",
                                streetAddress: company.streetAddress,
                                zipCode: company.zipCode,
                                town: company.town,
                            }}
                        />
                        <ContractTable
                            title={`${t("contracts.sections.companyDpas")} (${company.name})`}
                            contracts={dpas}
                            canCreate={companyHasActiveSubscription(company.id)}
                            onCreateClicked={onOpenCompanyDpaModalClicked(company.id)}
                        />
                    </div>
                ))}
                <div key={"userDpas"}>
                    <CreateUserDpaModal
                        isOpen={isUserDpaModalOpen}
                        close={onCloseUserDpaModalClicked}
                        userId={userId}
                        initialData={{
                            managementCompanyBusinessId: "",
                            managementCompanyStreetAddress: "",
                            managementCompanyZipCode: "",
                            managementCompanyTown: "",
                        }}
                    />
                    <ContractTable
                        title={t("contracts.sections.userDpas")}
                        contracts={sortedUserDpas}
                        canCreate={someCompanyHasActiveSubscription}
                        onCreateClicked={onOpenUserDpaModalClicked}
                    />
                </div>
            </Block>
        </ViewContainer>
    );
}
