import React, { useEffect } from "react";
import { Paper, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import { Block } from "../../components/common/block";
import { ModalInput } from "../../components/common/modal/ModalInput";
import SeparatorLine from "../../components/SeparatorLine";
import {
    TableHeadCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "../../components/Table";
import { Company } from "../../redux/companies/companies-types";
import {
    CreateNewUserPasswordAction,
    DeleteUserAction,
    UpdateUserAction,
    UpdateUserEmailAction,
} from "../../redux/users/users-actions";
import { getCompanyManagerByUser } from "../../redux/users/users-reducer";
import { User } from "../../redux/users/users-types";
import { alignments, spacings } from "../../theme";
import AdminRights from "./AdminRightsButton";
import FloatButtonsRight from "../../components/FloatButtonsRight";
import Checkbox from "../../components/Checkbox";
import { ReportErrorAction } from "../../redux/error/error-actions";
import { Link } from "react-router-dom";
import { RootState } from "../../interfaces/RootState";

function UsersCompanyRow({ c, user }: { c: Company; user: User }): JSX.Element {
    const t = useTranslation().t;
    const companyManager = getCompanyManagerByUser(c, user);

    return (
        <TableRow key={c.id}>
            <TableCell>
                <Link to={`/companies/${c.id}`}>{c.name}</Link>
            </TableCell>
            <TableCell>{t(`roles.${companyManager?.principal ? "principal" : companyManager?.role}`)}</TableCell>
        </TableRow>
    );
}

function UserCompanies({ companies, user }: { companies: Company[]; user: User }): JSX.Element {
    const t = useTranslation().t;

    return (
        <div style={{ padding: `${spacings.inlineSpacing} 0` }}>
            <h3>{t("users.roles")}</h3>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow key={"companyTable_head"}>
                            <TableHeadCell>{t("users.company")}</TableHeadCell>
                            <TableHeadCell>{t("users.role")}</TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((c: Company) => (
                            <UsersCompanyRow key={c.id} c={c} user={user} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function CreateUserDetailInput({
    field,
    userDetails,
    setUserDetails,
}: {
    field: keyof User;
    userDetails: User;
    setUserDetails: React.Dispatch<React.SetStateAction<User>>;
}): JSX.Element {
    const t = useTranslation().t;

    const changeValues = (e: React.ChangeEvent<HTMLInputElement>, field: keyof User) => {
        setUserDetails({
            ...userDetails,
            [field]: e.target.value,
        });
    };

    return (
        <ModalInput
            key={field}
            title={t(`users.${field}`)}
            value={userDetails[field] as string}
            handleFormValue={(e) => changeValues(e, field)}
        />
    );
}

type Props = {
    user: User;
    companies: Company[];
};

export default function UsersInfo({ user, companies }: Props): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const email = useSelector((state: RootState): string => state.hydrolink.auth.email);
    const canChangeUserAdminStatus = user.email !== email;

    const tooltipTitle: string = !canChangeUserAdminStatus ? t("users.cannotRemoveYourself") : "";

    const [originalUserDetails, setOriginalUserDetails] = React.useState<User>(user);
    const [userDetails, setUserDetails] = React.useState<User>(originalUserDetails);
    const [createNewPassword, setCreateNewPassword] = React.useState<boolean>(false);

    useEffect(() => {
        setOriginalUserDetails(user);
    }, [user]);

    const anythingChanged = (): boolean => basicDetailsChanged() || emailChanged() || createNewPassword;

    const emailChanged = (): boolean => originalUserDetails.email !== userDetails.email;

    const basicDetailsChanged = (): boolean =>
        !(
            originalUserDetails.firstName === userDetails.firstName &&
            originalUserDetails.lastName === userDetails.lastName &&
            originalUserDetails.phone === userDetails.phone
        );

    const deleteUser = () => {
        if (window.confirm(`${t("users.deleteUserConfirmation")}?`)) {
            dispatch(DeleteUserAction(user.id));
        }
    };

    const saveUser = () => {
        // Save stuff only if necessary
        if (basicDetailsChanged()) {
            if (userDetails.firstName === "") {
                dispatch(ReportErrorAction("errors.firstNameMissing"));
                return;
            }
            if (userDetails.lastName === "") {
                dispatch(ReportErrorAction("errors.lastNameMissing"));
                return;
            }

            dispatch(UpdateUserAction(userDetails));
        }
        if (emailChanged()) {
            dispatch(UpdateUserEmailAction({ userId: user.id, newEmail: userDetails.email }));
        }
        if (createNewPassword) {
            setCreateNewPassword(false);
            dispatch(CreateNewUserPasswordAction(user.id));
        }

        // `originalUserDetails` is used to keep track if there are any changes made (to disable/enable save button)
        setOriginalUserDetails(userDetails);
    };

    return (
        <Block noMargin noBottomBorder variant={"white"} style={{ overflow: "hidden" }}>
            <h3>{t("users.basicInfo")}</h3>
            <div style={alignments.twoElementGrid}>
                <CreateUserDetailInput field="firstName" userDetails={userDetails} setUserDetails={setUserDetails} />
                <CreateUserDetailInput field="lastName" userDetails={userDetails} setUserDetails={setUserDetails} />
                <CreateUserDetailInput field="phone" userDetails={userDetails} setUserDetails={setUserDetails} />
            </div>
            <SeparatorLine />
            <div style={alignments.twoElementGrid}>
                <CreateUserDetailInput field="email" userDetails={userDetails} setUserDetails={setUserDetails} />
                <div style={{ paddingTop: "48px" }}>
                    <Checkbox
                        label={t("users.generateNewPassword")}
                        checked={createNewPassword}
                        onChange={() => setCreateNewPassword(!createNewPassword)}
                    />
                </div>
            </div>
            <SeparatorLine />
            <UserCompanies companies={companies} user={user} />
            <FloatButtonsRight
                buttons={[
                    <Tooltip title={tooltipTitle}>
                        <div>
                            <Button disabled={!canChangeUserAdminStatus} onClick={deleteUser}>
                                {t("users.delete")}
                            </Button>
                        </div>
                    </Tooltip>,
                    <AdminRights user={user} canChangeUserAdminStatus={canChangeUserAdminStatus} />,
                    <Button variant="contained" onClick={saveUser} disabled={!anythingChanged()}>
                        {t("generic.save")}
                    </Button>,
                ]}
            />
        </Block>
    );
}
