import { createAction } from "@reduxjs/toolkit";
import {
    CreateUserDpaRequestPayload,
    CreateUserDpaRequestSuccessPayload,
    CreateCompanyDpaRequestPayload,
    CreateCompanyDpaRequestSuccessPayload,
    FetchUserContractsRequestPayload,
    FetchUserContractsSuccessPayload,
    SetSignRequestSentByContractIdPayload,
    SendSignRequestPayload,
    SendSignRequestSuccessPayload,
    CreateCompanySubscriptionRequestPayload,
    CreateCompanySubscriptionRequestSuccessPayload,
} from "./contracts-types";

export const FetchUserContractsAction = createAction<FetchUserContractsRequestPayload>("FETCH_USER_CONTRACTS");
export const FetchUserContractsSuccessAction =
    createAction<FetchUserContractsSuccessPayload>("FETCH_USER_CONTRACTS_SUCCESS");
export const FetchUserContractsFailureAction = createAction<void>("FETCH_USER_CONTRACTS_FAILURE");

export const CreateUserDPARequestAction = createAction<CreateUserDpaRequestPayload>("CREATE_USER_DPA_REQUEST");
export const CreateUserDPARequestSuccessAction = createAction<CreateUserDpaRequestSuccessPayload>(
    "CREATE_USER_DPA_REQUEST_SUCCESS",
);
export const CreateUserDPARequestFailureAction = createAction<void>("CREATE_USER_DPA_REQUEST_FAILURE");

export const CreateCompanySubscriptionRequestAction = createAction<CreateCompanySubscriptionRequestPayload>(
    "CREATE_COMPANY_SUBSCRIPTION_REQUEST",
);
export const CreateCompanySubscriptionRequestSuccessAction =
    createAction<CreateCompanySubscriptionRequestSuccessPayload>("CREATE_COMPANY_SUBSCRIPTION_REQUEST_SUCCESS");
export const CreateCompanySubscriptionRequestFailureAction = createAction<void>("CREATE_USER_DPA_REQUEST_FAILURE");

export const CreateCompanyDPARequestAction = createAction<CreateCompanyDpaRequestPayload>("CREATE_COMPANY_DPA_REQUEST");
export const CreateCompanyDPARequestSuccessAction = createAction<CreateCompanyDpaRequestSuccessPayload>(
    "CREATE_COMPANY_DPA_REQUEST_SUCCESS",
);
export const CreateCompanyDPARequestFailureAction = createAction<void>("CREATE_USER_DPA_REQUEST_FAILURE");

export const SendInviteeSignRequestAction = createAction<SendSignRequestPayload>("SEND_INVITEE_SIGN_REQUEST");
export const SendInviteeSignRequestSuccessAction = createAction<SendSignRequestSuccessPayload>(
    "SEND_INVITEE_SIGN_REQUEST_SUCCESS",
);
export const SendInviteeSignRequestFailureAction = createAction<void>("SEND_INVITEE_SIGN_REQUEST_FAILURE");
export const SetInviteeSignRequestSentByContractIdAction = createAction<SetSignRequestSentByContractIdPayload>(
    "SET_INVITEE_SIGN_REQUEST_SENT_BY_CONTRACT_ID",
);
