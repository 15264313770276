import { useTranslation } from "react-i18next";
import { TableRow, TableCell, Tooltip, Autocomplete, TextField } from "@mui/material";
import { Button } from "../../../../components/Button";
import React, { useMemo } from "react";
import { Company, Role } from "../../../../redux/companies/companies-types";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { ManagerRole } from "./ManagerRole";
import { User } from "../../../../redux/users/users-types";
import { CompanyUserWithState } from "./listEditor";
import { borders } from "../../../../theme";

const AddUserAsAdmin = ({
    company,
    allUsers,
    editedManagers,
    addUser,
}: {
    company: Company;
    allUsers: User[];
    editedManagers: CompanyUserWithState[];
    addUser: (id: number, role: Role) => void;
}) => {
    const t = useTranslation().t;
    const canSeeAllUsers = useHasPrivileges("can see all users", company.id).hasPrivilege;

    const canChangeCompanyUserRole = useHasPrivileges("can change company user role", company.id).hasPrivilege;
    const asAdminCanAddCompanyUser = useHasPrivileges("as an admin can add company user", company.id).hasPrivilege;

    const [selectedUserId, setSelectedUserId] = React.useState<string>("");
    const [role, setRole] = React.useState<Role>("no role");

    const getAddUserTooltip = () => {
        return selectedUserId === "" ? t("apartments.managersAddUserForAdmin") : "";
    };

    const add = () => {
        if (selectedUserId !== "" && asAdminCanAddCompanyUser) {
            addUser(parseInt(selectedUserId), role);
            setSelectedUserId("");
        }
    };

    const allUsersDropDownList = useMemo(
        () =>
            allUsers
                .filter((u) => userHasAName(u) && !userIsNotAlreadyInTheDropDownList(editedManagers, u.id))
                .map((u) => option(u)),
        [allUsers, editedManagers],
    );

    return (
        <TableRow>
            <TableCell colSpan={2}>
                {canSeeAllUsers && (
                    <Autocomplete
                        id="allUsers"
                        options={allUsersDropDownList.map((user) => user.value)}
                        sx={{ width: 300 }}
                        style={{
                            border: selectedUserId === "" ? borders.attentionBorder : "none",
                        }}
                        renderInput={(params) => <TextField {...params} label={t("generic.choose")} />}
                        getOptionLabel={(userId) =>
                            allUsersDropDownList.find((user) => user.value === userId)?.displayText ?? ""
                        }
                        onChange={(_e, newValue) => {
                            if (newValue) {
                                setSelectedUserId(newValue);
                            }
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                {canChangeCompanyUserRole ? <ManagerRole selectedValue={role} onchange={setRole} /> : null}
            </TableCell>
            <TableCell>
                <Tooltip title={getAddUserTooltip()}>
                    <span>
                        {" "}
                        {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                        <Button onClick={add} disabled={selectedUserId === "" || role === "no role"}>
                            {t("users.add")}
                        </Button>
                    </span>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

type DropDownItem = {
    value: string;
    displayText: string;
};

const userHasAName = (user: User) => user.firstName !== "" || user.lastName !== "";

function userIsNotAlreadyInTheDropDownList(currentCompanys: CompanyUserWithState[], userId: number) {
    return currentCompanys.some((cu) => cu.user.userId === userId);
}

function option(user: User): DropDownItem {
    return {
        value: user.id.toString(),
        displayText: `${user.firstName} ${user.lastName} (${user.email})`,
    };
}

export default AddUserAsAdmin;
