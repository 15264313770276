import { all, call, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { FetchApartmentsAction } from "../apartments/apartments-actions";
import { ReportErrorAction } from "../error/error-actions";
import { FetchUsersAction } from "../users/users-actions";

import {
    convertApiAlarmType,
    convertApiCompany,
    convertApiCompanyInvoiceInfo,
    createCreateCompanyRequestPayload,
    createUpdateUserRoleAsManagerRequestPayload,
    saveCompanyInvoiceRequestPayload,
    saveCompanyRequestPayload,
} from "../../api/api-conversions";
import {
    ApiCompany,
    FetchAlarmTypesSuccessRequestPayload,
    FetchCompanyInvoiceSuccessResponsePayload,
} from "../../api/api-types";
import { API_URL } from "../../constants/urls";
import selectIdToken from "../auth/select-id-token";
import {
    CloseCompanyBasicInfoModal,
    CreateCompanyAction,
    CreateCompanyFailureAction,
    CreateCompanySuccessAction,
    DeleteCompanyAction,
    DeleteCompanySuccessAction,
    DeleteCompanyUserRoleAction,
    DeleteCompanyUserRoleFailureAction,
    DeleteCompanyUserRoleSuccessAction,
    DownloadIntegrationMeterIdsAction,
    DownloadIntegrationMeterIdsFailureAction,
    DownloadIntegrationMeterIdsSuccessAction,
    FetchAlarmTypesAction,
    FetchAlarmTypesFailureAction,
    FetchAlarmTypesSuccessAction,
    FetchCompaniesFailureAction,
    FetchCompaniesSuccessAction,
    FetchCompaniesWithoutAlarmsAction,
    FetchCompanyApiKeyAction,
    FetchCompanyApiKeyFailureAction,
    FetchCompanyApiKeySuccessAction,
    FetchCompanyInvoiceInfoAction,
    FetchCompanyInvoiceInfoFailureAction,
    FetchCompanyInvoiceInfoSuccessAction,
    FetchCompanyWarningNamesAction,
    FetchCompanyWarningNamesSuccessAction,
    ManageCompanyUsersAction,
    ManageCompanyUsersFailureAction,
    ManageCompanyUsersSuccessAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedFailureAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedSuccessAction,
    MergeApartmentsAction,
    MergeApartmentsFailureAction,
    MergeApartmentsSuccessAction,
    PremiumToggleAction,
    PremiumToggleFailureAction,
    PremiumToggleSuccessAction,
    RefreshCompanyApiKeyAction,
    RefreshCompanyApiKeyFailureAction,
    RefreshCompanyApiKeySuccessAction,
    SaveCompanyBasicInfoAction,
    SaveCompanyBasicInfoFailureAction,
    SaveCompanyBasicInfoSuccessAction,
    SaveCompanyInvoiceInfoAction,
    SaveCompanyInvoiceInfoFailureAction,
    SaveCompanyInvoiceInfoSuccessAction,
    UpdateApiAccessAction,
    UpdateApiAccessSuccessAction,
    UpdateUserAsManagerAction,
    UpdateUserAsManagerFailureAction,
    UpdateUserAsManagerSuccessAction,
    UpdateUserRolesAction,
    UpdateUserRolesFailureAction,
    UpdateUserRolesSuccessAction,
} from "./companies-actions";
import {
    CreateCompanyPayload,
    CreateNewCompanyUserPayload,
    DeleteCompanyPayload,
    DeleteCompanyUserRolePayload,
    FetchCompanyApiKeyInput,
    FetchCompanyInvoicePayload,
    FetchCompanyWarningNamesPayload,
    FetchCompanyWarningNamesSuccessPayload,
    ManageCompanyUsersPayload,
    MarkNewPrincipalManagerMessageAsAcknowledgedPayload,
    MergeApartmentsPayload,
    PremiumTogglePayload,
    RefreshCompanyApiKeyInput,
    SaveCompanyInvoicePayload,
    SaveCompanyPayload,
    UpdateApiAccessPayload,
    UpdateCompanyUserPayload,
    UpdateUserRoleAsManagerPayload,
    UpdateUserRolesPayload,
} from "./companies-types";

export function* fetchCompaniesForCompanyList() {
    yield api.get(
        `/companiesforcompanylist`,
        (resp: ApiCompany[]) => put(FetchCompaniesSuccessAction(resp.map(convertApiCompany))),
        (err) => all([put(FetchCompaniesFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* fetchCompanyAlarmNames(action: { payload: FetchCompanyWarningNamesPayload }) {
    if (action.payload.companyIds.length > 0) {
        let companyString = action.payload.companyIds.join(",");

        yield api.get(
            `/alarms/names/${companyString}`,
            (resp: FetchCompanyWarningNamesSuccessPayload[]) => put(FetchCompanyWarningNamesSuccessAction(resp)),
            (err) => all([put(ReportErrorAction(err))]),
        );
    }
}

export function* fetchCompanyApiKey(action: { payload: FetchCompanyApiKeyInput }) {
    const companyId: number = action.payload.companyId;
    type Response = { apiKey: string | null };

    yield api.get(
        `/companies/${companyId}/api_key`,
        (resp: Response) =>
            put(
                FetchCompanyApiKeySuccessAction({
                    apiKey: resp.apiKey,
                    companyId: companyId,
                }),
            ),
        (err) => all([put(FetchCompanyApiKeyFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* refreshCompanyApiKey(action: { payload: RefreshCompanyApiKeyInput }) {
    const companyId: number = action.payload.companyId;
    type Response = { newApiKey: string | null };

    yield api.post(
        `/companies/${companyId}/api_key`,
        {},
        (resp: Response) =>
            put(
                RefreshCompanyApiKeySuccessAction({
                    newApiKey: resp.newApiKey,
                    companyId: companyId,
                }),
            ),
        (err) => all([put(RefreshCompanyApiKeyFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* createCompany(action: { type: string; payload: CreateCompanyPayload }) {
    const body = createCreateCompanyRequestPayload(action.payload);
    type Response = { newCompanyId: number };

    yield api.post(
        `/companies`,
        body,
        (res: Response) =>
            all([
                put(CreateCompanySuccessAction(res.newCompanyId)),
                put(FetchCompaniesWithoutAlarmsAction()),
                call(() => action.payload.navigate(`/companies/${res.newCompanyId}`)),
            ]),
        (err) => all([put(CreateCompanyFailureAction(err)), put(ReportErrorAction(err))]),
    );
}

export function* updateUserRoles(action: { payload: UpdateUserRolesPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.put(
        `/companies/${companyId}/user_roles`,
        { users: action.payload.users },
        () => all([put(UpdateUserRolesSuccessAction()), put(FetchCompaniesWithoutAlarmsAction())]),
        (err) =>
            all([
                put(UpdateUserRolesFailureAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
                put(ReportErrorAction(err)),
            ]),
    );
}

export function* manageCompanyUsers(action: { payload: ManageCompanyUsersPayload }) {
    const companyId: number = action.payload.companyId;
    const toCreate: CreateNewCompanyUserPayload[] = action.payload.toCreate;
    const toUpdate: UpdateCompanyUserPayload[] = action.payload.toUpdate;
    const toDelete: number[] = action.payload.toDelete;

    yield api.patch(
        `/companies/${companyId}/company_users`,
        {
            toCreate: toCreate,
            toUpdate: toUpdate,
            toDelete: toDelete,
        },
        () => all([put(ManageCompanyUsersSuccessAction()), put(FetchCompaniesWithoutAlarmsAction())]),
        (err) =>
            all([
                put(ManageCompanyUsersFailureAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
                put(ReportErrorAction(err)),
            ]),
    );
}

export function* removeCompanyUserRole(action: { payload: DeleteCompanyUserRolePayload }) {
    const companyId: number = action.payload.companyId;
    const userId: number = action.payload.userId;

    yield api.remove(
        `/companies/${companyId}/user_roles/${userId}`,
        {},
        () => all([put(DeleteCompanyUserRoleSuccessAction()), put(FetchCompaniesWithoutAlarmsAction())]),
        (err) => all([put(DeleteCompanyUserRoleFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* updateUserRoleAsManager(action: { payload: UpdateUserRoleAsManagerPayload }) {
    const companyId: number = action.payload.companyId;
    const body = createUpdateUserRoleAsManagerRequestPayload(action.payload);

    yield api.post(
        `/companies/${companyId}/users`,
        body,
        () => all([put(UpdateUserAsManagerSuccessAction()), put(FetchCompaniesWithoutAlarmsAction())]),
        (err) =>
            all([
                put(UpdateUserAsManagerFailureAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
                put(ReportErrorAction(err)),
            ]),
    );
}

export function* reloadCompanyUsers() {
    yield put(FetchUsersAction());
    yield put(FetchCompaniesWithoutAlarmsAction());
}

export function* saveCompanyBasicInfo(action: { payload: SaveCompanyPayload }) {
    const companyId: number = action.payload.id;
    const body = saveCompanyRequestPayload(action.payload);

    yield api.put(
        `/companies/${companyId}/basic_info`,
        body,
        () =>
            all([
                put(SaveCompanyBasicInfoSuccessAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
                put(CloseCompanyBasicInfoModal()),
            ]),
        (err) => all([put(SaveCompanyBasicInfoFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* updateApiAccess(action: { payload: UpdateApiAccessPayload }) {
    const companyId: number = action.payload.companyId;
    const apiAccessEnabled: boolean = action.payload.apiAccessEnabled;

    yield api.put(
        `/companies/${companyId}/api_access`,
        {
            apiAccessEnabled: apiAccessEnabled,
        },
        () => put(UpdateApiAccessSuccessAction(action.payload)),
        (err: string) => put(ReportErrorAction(err)),
    );
}

export function* premiumToggle(action: { payload: PremiumTogglePayload }) {
    const companyId: number = action.payload.companyId;
    const premium: boolean = action.payload.premium;

    yield api.put(
        `/companies/${companyId}/premium_toggle`,
        {
            premium: premium,
        },
        () => all([put(PremiumToggleSuccessAction()), put(FetchCompaniesWithoutAlarmsAction())]),
        (err: string) => all([put(ReportErrorAction(err)), put(PremiumToggleFailureAction())]),
    );
}

export function* deleteCompany(action: { payload: DeleteCompanyPayload }) {
    const companyId: number = action.payload;

    yield api.remove(
        `/companies/${companyId}`,
        {},
        () => all([put(FetchCompaniesWithoutAlarmsAction()), put(DeleteCompanySuccessAction())]),
        (err: string) => all([put(ReportErrorAction(err))]),
    );
}

export function* mergeApartments(action: { payload: MergeApartmentsPayload }) {
    const companyId: number = action.payload.companyId;
    const targetApartmentId: number = action.payload.targetApartmentId;
    const sourceApartmentIds: number[] = action.payload.sourceApartmentIds;

    yield api.post(
        `/companies/${companyId}/apartments/${targetApartmentId}/merge`,
        { sourceApartmentIds: sourceApartmentIds },
        () => all([put(MergeApartmentsSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(MergeApartmentsFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* fetchAlarmTypes() {
    yield api.get(
        "/alarm_types",
        (resp: FetchAlarmTypesSuccessRequestPayload) =>
            put(FetchAlarmTypesSuccessAction(resp.map((a) => convertApiAlarmType(a)))),
        () => put(FetchAlarmTypesFailureAction()),
    );
}

export function* saveCompanyInvoiceInfo(action: { payload: SaveCompanyInvoicePayload }) {
    const companyId: number = action.payload.companyId;
    const body = saveCompanyInvoiceRequestPayload(action.payload);

    yield api.put(
        `/companies/${companyId}/invoice_info`,
        body,
        () =>
            all([
                put(SaveCompanyInvoiceInfoSuccessAction()),
                put(FetchCompanyInvoiceInfoAction({ companyId: action.payload.companyId })),
            ]),
        (err) => all([put(SaveCompanyInvoiceInfoFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* fetchCompanyInvoiceInfo(action: { payload: FetchCompanyInvoicePayload }) {
    const companyId: number = action.payload.companyId;
    yield api.get(
        `/companies/${companyId}/invoice_info`,
        (resp: FetchCompanyInvoiceSuccessResponsePayload) =>
            put(FetchCompanyInvoiceInfoSuccessAction(convertApiCompanyInvoiceInfo(resp))),
        () => put(FetchCompanyInvoiceInfoFailureAction()),
    );
}

export function* markNewPrincipalManagerMessageAsAcknowledged(action: {
    payload: MarkNewPrincipalManagerMessageAsAcknowledgedPayload;
}) {
    const companyId: number = action.payload.companyId;
    yield api.post(
        `/companies/${companyId}/new_principal_manager_message_acknowledgement`,
        {},
        () =>
            all([
                put(MarkNewPrincipalManagerMessageAsAcknowledgedSuccessAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
            ]),
        (err: string) =>
            all([put(MarkNewPrincipalManagerMessageAsAcknowledgedFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* downloadIntegrationMeterIds(action: { payload: number }) {
    const idToken: string = yield select(selectIdToken);
    const companyId: number = action.payload;

    yield api.download(
        `${API_URL}/companies/${companyId}/integration_meter_ids.csv`,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(DownloadIntegrationMeterIdsSuccessAction())],
        [put(DownloadIntegrationMeterIdsFailureAction())],
    );
}

export function* companiesSaga() {
    yield takeEvery(FetchCompaniesWithoutAlarmsAction, fetchCompaniesForCompanyList);
    yield takeEvery(FetchCompanyWarningNamesAction, fetchCompanyAlarmNames);
    yield takeEvery(FetchCompanyApiKeyAction, fetchCompanyApiKey);
    yield takeEvery(RefreshCompanyApiKeyAction, refreshCompanyApiKey);
    yield takeEvery(CreateCompanyAction, createCompany);
    yield takeEvery(SaveCompanyBasicInfoAction, saveCompanyBasicInfo);
    yield takeEvery(UpdateUserRolesAction, updateUserRoles);
    yield takeEvery(UpdateUserAsManagerAction, updateUserRoleAsManager);
    yield takeEvery(DeleteCompanyUserRoleAction, removeCompanyUserRole);
    yield takeEvery(UpdateUserAsManagerSuccessAction, reloadCompanyUsers);
    yield takeEvery(UpdateApiAccessAction, updateApiAccess);
    yield takeEvery(PremiumToggleAction, premiumToggle);
    yield takeEvery(DeleteCompanyAction, deleteCompany);
    yield takeEvery(MergeApartmentsAction, mergeApartments);
    yield takeEvery(FetchAlarmTypesAction, fetchAlarmTypes);
    yield takeEvery(SaveCompanyInvoiceInfoAction, saveCompanyInvoiceInfo);
    yield takeEvery(FetchCompanyInvoiceInfoAction, fetchCompanyInvoiceInfo);
    yield takeEvery(ManageCompanyUsersAction, manageCompanyUsers);
    yield takeEvery(MarkNewPrincipalManagerMessageAsAcknowledgedAction, markNewPrincipalManagerMessageAsAcknowledged);
    yield takeEvery(DownloadIntegrationMeterIdsAction, downloadIntegrationMeterIds);
}
