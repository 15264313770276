import { Refresh } from "@mui/icons-material";
import { Alert, AlertTitle, IconButton, Link, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FetchUserInformationAction } from "../../../redux/auth/auth-actions";
import { spacings } from "../../../theme";
import Centered from "../../Centered";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../utils/store-helpers";
import { useMemo } from "react";

export default function MissingContractsNotification(): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const location = useLocation();
    const contractsViewUrl = "/current-user-contracts";

    const roleByCompanyId = useAppSelector((state) => state.hydrolink.auth.roleByCompanyId);
    const hasMissingOrPendingContracts = useMemo(
        () =>
            Object.values(roleByCompanyId).some(
                ({ role, principal, requiredContractsSigned }) =>
                    role === "manager" && principal && !requiredContractsSigned,
            ),
        [roleByCompanyId],
    );

    const checkForSignatures = () => {
        dispatch(FetchUserInformationAction());
    };

    if (!hasMissingOrPendingContracts || location.pathname === contractsViewUrl) {
        return <></>;
    }
    return (
        <Centered style={{ marginBottom: spacings.standardSpacing }}>
            <Alert
                severity="warning"
                style={{ width: "640px" }}
                action={
                    <Tooltip title={t("contracts.notifications.pendingContracts.checkForSignatures")}>
                        <IconButton onClick={checkForSignatures}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                }
            >
                <AlertTitle>
                    <strong>{t("contracts.notifications.pendingContracts.title")}</strong>
                </AlertTitle>
                <p style={{ whiteSpace: "break-spaces" }}>
                    {t("contracts.notifications.pendingContracts.attention")}
                    {/* TODO: Different text when company and KOKA */}
                </p>
                <p style={{ whiteSpace: "break-spaces" }}>
                    {t("contracts.notifications.pendingContracts.description")}{" "}
                    {/* TODO: Different text when company and KOKA */}
                </p>
                <p style={{ whiteSpace: "break-spaces" }}>{t("contracts.notifications.pendingContracts.guide")}</p>
                <p>
                    <Link href={contractsViewUrl}>
                        {t("contracts.notifications.pendingContracts.openContractsView")}
                    </Link>
                </p>
            </Alert>
        </Centered>
    );
}
