import { faTrash, faWrench } from "@fortawesome/free-solid-svg-icons";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import ExpandableBlock from "../../components/ExpandableBlock";
import FloatButtonsRight from "../../components/FloatButtonsRight";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import { ViewContainer } from "../../components/ViewContainer";
import { Block } from "../../components/common/block";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { RootState } from "../../interfaces/RootState";
import {
    ClearApartmentsAction,
    DeleteAllApartmentsAction,
    FetchAlarmSettingsAction,
    FetchApartmentsAction,
    FetchPremiumSettingsAction,
    FetchReportSettingsAction,
} from "../../redux/apartments/apartments-actions";
import {
    FetchAlarmTypesAction,
    FetchCompaniesWithoutAlarmsAction,
    FetchCompanyWarningNamesAction,
    MergeApartmentsAction,
} from "../../redux/companies/companies-actions";
import { selectCompanyApiKey } from "../../redux/companies/companies-reducer";
import { Company } from "../../redux/companies/companies-types";
import { FetchUsersAction } from "../../redux/users/users-actions";
import { alignments, spacings } from "../../theme";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import CompanyTabs, { Tabs } from "../companies/CompanyTabs";
import NewPrincipalManagerMessageModal from "./NewPrincipalManagerMessageModal";
import UploadCSV from "./UploadCSV";
import ApartmentList from "./apartments/ApartmentList";
import CompanyInfo from "./company-info/CompanyInfo";
import PremiumSettings from "./premium-settings/PremiumSettings";

type Props = {
    company: Company;
    apiKey: string | null;
};

function ApartmentsComponent({ company, apiKey }: Props): ReactElement {
    const { t } = useTranslation();
    const [mergeApartmentsOn, setMergeApartmentsOn] = React.useState<boolean>(false);
    const [apartmentsToMerge, setApartmentsToMerge] = React.useState<number[]>([]);
    const [blockIsOpen, setBlockOpen] = React.useState<boolean | undefined>(undefined);
    const canUploadCSV = useHasPrivileges("can upload apartments from csv", company.id).hasPrivilege;
    const canMergeApartments = useHasPrivileges("can merge apartments", company.id);
    const canDeleteApartments = useHasPrivileges("can delete apartment", company.id);

    const canSeePremiumSettings = useHasPrivileges("can see premium settings", company.id).hasPrivilege;

    const dispatch = useDispatch();

    const mergeApartments = () => {
        dispatch(
            MergeApartmentsAction({
                companyId: company.id,
                targetApartmentId: apartmentsToMerge[0],
                sourceApartmentIds: apartmentsToMerge.slice(1),
            }),
        );
        resetMergeApartments();
    };

    const resetMergeApartments = () => {
        setApartmentsToMerge([]);
        setMergeApartmentsOn(false);
    };

    const toggleMergingApartments = () => {
        if (!blockIsOpen && !mergeApartmentsOn) {
            setBlockOpen(true);
        }
        setMergeApartmentsOn(!mergeApartmentsOn);
    };

    const deleteAllApartments = () => {
        if (window.confirm(t("apartments.deleteAllConfirmation"))) {
            dispatch(DeleteAllApartmentsAction(company.id));
        }
    };

    const toggleOpen = () => {
        setBlockOpen(!blockIsOpen);
    };

    useEffect(() => {
        if (company.alarmLoadingState === undefined) {
            dispatch(FetchCompanyWarningNamesAction({ companyIds: [company.id] }));
        }
    }, [dispatch, company.alarmLoadingState, company.id]);

    return (
        <ViewContainer>
            {company.displayNewPrincipalManagerMessage && <NewPrincipalManagerMessageModal company={company} />}
            <CompanyTabs tab={Tabs.COMPANY} />
            <ExpandableBlock
                isOpen={blockIsOpen}
                onToggleOpen={toggleOpen}
                leftSide={
                    <>
                        {company.name} - {t("apartments.apartments")}
                    </>
                }
                rightSide={
                    <div
                        style={{
                            ...alignments.evenHorizontal,
                            marginRight: spacings.standardSpacing,
                            maxHeight: "20px",
                        }}
                    >
                        {mergeApartmentsOn && (
                            <FloatButtonsRight
                                noTopMargin
                                buttons={[
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            resetMergeApartments();
                                        }}
                                    >
                                        {t("apartments.cancel")}
                                    </Button>,
                                    <Button
                                        disabled={apartmentsToMerge.length <= 1}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            mergeApartments();
                                        }}
                                    >
                                        {t("apartments.mergeApartments")}
                                    </Button>,
                                ]}
                            />
                        )}
                        <FontAwesomeIconButton
                            tooltip={t("apartments.mergeApartmentsTooltip")}
                            disabled={!canMergeApartments.hasPrivilege}
                            disabledTooltip={canMergeApartments.missingRequirement}
                            style={mergeApartmentsOn ? { transform: "rotate(-180deg)" } : {}}
                            icon={faWrench}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleMergingApartments();
                            }}
                        />
                        <FontAwesomeIconButton
                            tooltip={t("apartments.deleteAll")}
                            disabled={!canDeleteApartments.hasPrivilege}
                            disabledTooltip={canDeleteApartments.missingRequirement}
                            icon={faTrash}
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteAllApartments();
                            }}
                        />
                    </div>
                }
                grey
            >
                <ApartmentList
                    company={company}
                    clients={company.clients}
                    mergeApartmentsOn={mergeApartmentsOn}
                    setApartmentsToMerge={setApartmentsToMerge}
                    apartmentsToMerge={apartmentsToMerge}
                />
            </ExpandableBlock>
            <CompanyInfo company={company} clients={company.clients} apiKey={apiKey} />
            {canUploadCSV && (
                <ExpandableBlock leftSide={t("csv.addApartments")} grey>
                    <Block variant="white" style={{ marginTop: spacings.inlineSpacing }}>
                        <UploadCSV companyId={company.id} />
                    </Block>
                </ExpandableBlock>
            )}
            {canSeePremiumSettings && (
                <ExpandableBlock leftSide={t("apartments.premiumSettings.title")} grey>
                    <PremiumSettings company={company} />
                </ExpandableBlock>
            )}
        </ViewContainer>
    );
}

export default function Apartments() {
    const dispatch = useDispatch();
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");
    const company = useSelector(selectCompany(companyId));
    const apiKey = useSelector(selectCompanyApiKey(companyId));
    const navigate = useNavigate();

    useEffect(() => {
        if (company === undefined) {
            dispatch(FetchCompaniesWithoutAlarmsAction());
        }
    }, [company, dispatch]);

    useEffect(() => {
        dispatch(ClearApartmentsAction());
        dispatch(FetchUsersAction());
        dispatch(FetchAlarmTypesAction());
        dispatch(FetchApartmentsAction({ companyId }));
        dispatch(FetchPremiumSettingsAction({ companyId }));
        dispatch(FetchAlarmSettingsAction({ companyId }));
        dispatch(FetchReportSettingsAction({ companyId }));
    }, [companyId, dispatch]);

    if (company === undefined) {
        navigate("/companies", { replace: true });
        return null;
    }

    return elementWithHeaderAndFooter(<ApartmentsComponent company={company} apiKey={apiKey} />);
}

const selectCompany = (companyId: number) => (state: RootState) =>
    state.hydrolink.companies.companies.find((c) => c.id === companyId);
