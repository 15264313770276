import JsFileDownloader from "js-file-downloader";
import { all, call, Effect, select, put as putEffect } from "redux-saga/effects";
import { API_URL } from "../constants/urls";
import selectIdToken from "../redux/auth/select-id-token";
import { ReportErrorAction } from "../redux/error/error-actions";
import { DownloadError } from "../utils/utils";
import AddLanguageToURL from "./AddLanguageToURL";
import { apiError, fetchError } from "./errors";

export type Action<T> = { type: string; payload: T };

export function* request<TRequest, TResponse, TResult, TError>(
    method: "POST" | "GET" | "PUT" | "DELETE" | "PATCH",
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    try {
        if (!path.startsWith("/")) {
            path = "/" + path;
        }
        path = AddLanguageToURL(path);

        const idToken: string = yield select(selectIdToken);
        const result: TResponse = yield call(() => {
            return fetch(`${API_URL}${path}`, {
                method,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return apiError(response);
                }
            });
        });
        yield onOk(result);
    } catch (error: any) {
        yield fetchError(error, onErr);
    }
}

export function* post<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    yield request("POST", path, body, onOk, onErr);
}

export function* patch<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    yield request("PATCH", path, body, onOk, onErr);
}

export function* put<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    yield request("PUT", path, body, onOk, onErr);
}

export function* remove<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    yield request("DELETE", path, body, onOk, onErr);
}

export function* get<TResponse, TResult, TError>(
    path: string,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    yield request("GET", path, undefined, onOk, onErr);
}

export function* download<TSuccessEffects, TFailureEffects, TRequest>(
    url: string,
    method: "GET" | "POST",
    body: TRequest,
    headers: { name: string; value: string }[],
    successActions: TSuccessEffects[],
    failureActions: TFailureEffects[],
) {
    const result: Effect = yield call(() =>
        new JsFileDownloader({
            url: AddLanguageToURL(url),
            method,
            body: body ? JSON.stringify(body) : null,
            headers,
            contentType: "application/json", // Because why would anyone need to use anything else? (if you do refactor this)
        })
            .then(() => {
                return all([...successActions]);
            })
            .catch((err: DownloadError) => {
                // `request.responseText` won't work here as the `responseType` is `arraybuffer`
                // so we have to decode it to a normal string
                let errorMessage = new TextDecoder().decode(err.request.response);

                return all([putEffect(ReportErrorAction(errorMessage)), ...failureActions]);
            }),
    );

    yield result;
}
