import { Button } from "../../components/Button";
import { ContractState } from "../../redux/contracts/contracts-types";
import { useAppDispatch, useAppSelector } from "../../utils/store-helpers";
import { useTranslation } from "react-i18next";
import {
    SendInviteeSignRequestAction,
    SetInviteeSignRequestSentByContractIdAction,
} from "../../redux/contracts/contracts-actions";

export type Props = {
    contractId: number;
    contractState: ContractState;
};

export default function SendqSignRequestButton({ contractId, contractState }: Props): JSX.Element | null {
    const t = useTranslation().t;
    const dispatch = useAppDispatch();
    const inviteeSignRequestSent =
        useAppSelector((state) => state.hydrolink.contracts.inviteeSignRequestSentByContractId?.[contractId]) ?? false;

    const sendInviteeSignRequestButton = () => {
        dispatch(SendInviteeSignRequestAction({ contractId: contractId }));
        setTimeout(() => {
            dispatch(SetInviteeSignRequestSentByContractIdAction({ contractId: contractId }));
        }, 10000);
    };

    if (contractState !== ContractState.PENDING) {
        return null;
    }
    return (
        <Button onClick={sendInviteeSignRequestButton} disabled={inviteeSignRequestSent}>
            {t("contracts.buttons.sendSignRequest")}
        </Button>
    );
}
