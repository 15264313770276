import BaseState from "../../interfaces/BaseState";

export type FetchUserContractsRequestPayload = { userId: number };
export type FetchUserContractsSuccessPayload = { userId: number; contracts: UserContracts };
export type SignRequestSentByContractId = Record<number, boolean>;

export type Contract = {
    id: number;
    createdAt: string;
    updatedAt: string;
    providerId: string;
    providerName: string;
    providerContractId: string;
    state: ContractState;
};

export enum ContractState {
    UNKNOWN,
    PENDING,
    CANCELLED,
    ACTIVE,
}

export type UserContracts = {
    userDpaContracts: Contract[];
    companySubscriptionContractsByCompanyId: Record<number, Contract[]>;
    companyDpaContractsByCompanyId: Record<number, Contract[]>;
};

export interface ContractsState extends BaseState {
    userContractsByUserId: Record<number, UserContracts>;
    inviteeSignRequestSentByContractId: SignRequestSentByContractId;
}

export interface CreateUserDpaRequestPayload {
    userId: number;
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
}

export interface CreateUserDpaRequestSuccessPayload {
    userId: number;
    created: Contract;
}

export interface CreateCompanySubscriptionRequestPayload {
    userId: number; // used to refresh the user contracts state after creation
    companyId: number;
    housingCompanyBusinessId: string;
    housingCompanyStreetAddress: string;
    housingCompanyZipCode: string;
    housingCompanyTown: string;
    managementCompanyName: string;
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
    managerPhone: string;
}

export interface CreateCompanySubscriptionRequestSuccessPayload {
    companyId: number;
    created: Contract;
}

export interface CreateCompanyDpaRequestPayload {
    userId: number; // used to refresh the user contracts state after creation
    companyId: number;
    businessId: string;
    streetAddress: string;
    zipCode: string;
    town: string;
}

export interface CreateCompanyDpaRequestSuccessPayload {
    companyId: number;
    created: Contract;
}

export interface SendSignRequestPayload {
    contractId: number;
}

export interface SetSignRequestSentByContractIdPayload {
    contractId: number;
}

export interface SendSignRequestSuccessPayload {
    contractId: number;
}
