import { LogoutSuccessAction } from "./auth-actions";
import { HydrolinkDispatch } from "../../configureStore";
import { clearToken } from "../../utils/persistence-utils";

export function logout() {
    return (dispatch: HydrolinkDispatch) => {
        clearToken();
        return dispatch(LogoutSuccessAction());
    };
}
