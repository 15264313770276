import {
    LockSuccessAction,
    LogoutSuccessAction,
    FetchUserInformationAction,
    UserReceivedAction,
    UserReceivedFailureAction,
    SetIdTokenAction,
    ForceLogoutAction,
} from "./auth-actions";
import { AuthState } from "./auth-types";
import { AnyAction } from "redux";
import { clearToken } from "../../utils/persistence-utils";
import { lastLoginErrorKey } from "../../constants/lastLoginErrorKey";
import { redirectUrl } from "../../redirectUrl";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";

const initialState: AuthState = {
    loading: 0,
    isAuthenticated: false,
    termsAndConditionsAccepted: false,
    id: 0,
    email: "",
    isAdmin: false,
    idToken: "",
    errorMessage: null,
    roleByCompanyId: {},
    contractFeatureEnabled: false,
};

export default function authReducer(state: AuthState = initialState, action: AnyAction): AuthState {
    if (LockSuccessAction.match(action)) {
        return {
            ...state,
            isAuthenticated: true,
            errorMessage: null,
            idToken: action.payload.token,
        };
    }

    if (LogoutSuccessAction.match(action)) {
        return {
            ...state,
            isAuthenticated: false,
        };
    }

    if (FetchUserInformationAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UserReceivedAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            id: action.payload.id,
            email: action.payload.email,
            isAdmin: action.payload.admin,
            termsAndConditionsAccepted: action.payload.termsAndConditionsAccepted,
            roleByCompanyId: action.payload.roleByCompanyId,
            contractFeatureEnabled: action.payload.contractFeatureEnabled,
        };
    }

    if (UserReceivedFailureAction.match(action)) {
        localStorage.setItem(lastLoginErrorKey, JSON.stringify(action.payload));
        return {
            ...stateWithLoadingFalse(state),
            errorMessage:
                "Käyttäjätietojen haku epäonnistui, istuntosi on mahdollisesti vanhentunut. Kokeile kirjautua sisään uudelleen",
        };
    }

    if (SetIdTokenAction.match(action)) {
        return {
            ...state,
            idToken: action.payload,
            isAuthenticated: true,
        };
    }

    if (ForceLogoutAction.match(action)) {
        clearToken();
        window.location.replace(redirectUrl());
    }

    return state;
}
