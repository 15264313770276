import { createAction } from "@reduxjs/toolkit";
import {
    FetchUserSuccessPayload,
    LockSuccessPayload,
    SendCurrentUserPasswordResetPayload,
    SendPasswordResetPayload,
} from "./auth-types";

export const LockSuccessAction = createAction<LockSuccessPayload>("LOCK_SUCCESS");
export const LogoutSuccessAction = createAction<void>("LOGOUT_SUCCESS");

export const FetchUserInformationAction = createAction<void>("FETCH_USER_INFORMATION");
export const UserReceivedAction = createAction<FetchUserSuccessPayload>("USER_RECEIVED");
export const UserReceivedFailureAction = createAction<string>("USER_RECEIVED_FAILURE");

export const ForceLogoutAction = createAction<void>("FORCE_LOGOUT");
export const SetIdTokenAction = createAction<string>("SET_ID_TOKEN");

export const SendPasswordResetAction = createAction<SendPasswordResetPayload>("SEND_PASSWORD_RESET");
export const SendPasswordResetSuccessAction = createAction<void>("SEND_PASSWORD_RESET_SUCCESS");
export const SendPasswordResetFailureAction = createAction<void>("SEND_PASSWORD_RESET_FAILURE");

export const AcceptTermsAndConditionsAction = createAction<void>("ACCEPT_TERMS_AND_CONDITIONS");
export const AcceptTermsAndConditionsSuccessAction = createAction<void>("ACCEPT_TERMS_AND_CONDITIONS_SUCCESS");
export const AcceptTermsAndConditionsFailureAction = createAction<void>("ACCEPT_TERMS_AND_CONDITIONS_FAILURE");

export const SendCurrentUserPasswordResetAction = createAction<SendCurrentUserPasswordResetPayload>(
    "SEND_CURRENT_USER_PASSWORD_RESET",
);
export const SendCurrentUserPasswordResetSuccessAction = createAction<void>("SEND_CURRENT_USER_PASSWORD_RESET_SUCCESS");
export const SendCurrentUserPasswordResetFailureAction = createAction<void>("SEND_CURRENT_USER_PASSWORD_RESET_FAILURE");

export const SetLanguageAction = createAction<string>("SET_LANGUAGE");
