import { useTranslation } from "react-i18next";
import { TableRow, TableCell, TextField, Tooltip } from "@mui/material";
import { Button } from "../../../../components/Button";
import React, { useRef } from "react";
import { Company, Role } from "../../../../redux/companies/companies-types";
import { emailRegex } from "../../../../utils/utils";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { ManagerRole } from "./ManagerRole";
import { NewUser } from "./Managers";

const AddUserAsManager = ({ company, addUser }: { company: Company; addUser: (newUser: NewUser) => void }) => {
    const t = useTranslation().t;
    const asAdminCanAddCompanyUser = useHasPrivileges("as an admin can add company user", company.id).hasPrivilege;

    const [newUserEmail, setNewUserEmail] = React.useState<string>("");
    const [newUserFirstName, setNewUserFirstName] = React.useState<string>("");
    const [newUserLastName, setNewUserLastName] = React.useState<string>("");
    const [newUserId, setNewUserId] = React.useState<number>(-1);
    const [role, setRole] = React.useState<Role>("no role");
    const newUserLastNameField = useRef();
    const newUserFirstNameField = useRef();

    const getAddUserTooltip = () => {
        if (canAddUser()) {
            return "";
        }

        return asAdminCanAddCompanyUser
            ? t("apartments.managersAddUserForAdmin")
            : t("apartments.managersAddUserForManager");
    };

    const errorOnNewUserEmailField = () => newUserEmail.length > 0 && !emailRegex.test(newUserEmail);

    const addUserFieldsEmpty = () =>
        !(newUserEmail.length > 0 && newUserLastName.length > 0 && newUserFirstName.length > 0);

    const canAddUser = () => !addUserFieldsEmpty() && !errorOnNewUserEmailField() && role !== "no role";

    const add = () => {
        if (newUserEmail !== "") {
            let id = newUserId;
            const newUser: NewUser = {
                id: id,
                email: newUserEmail,
                firstName: newUserFirstName,
                lastName: newUserLastName,
                role: role,
            };
            addUser(JSON.parse(JSON.stringify(newUser)));
            setNewUserEmail("");
            setNewUserFirstName("");
            setNewUserLastName("");
            setNewUserId(newUserId - 1);
        }
    };

    const changeRole = (role: Role) => {
        setRole(role);
    };

    return (
        <TableRow>
            <TableCell>
                <TextField
                    variant="standard"
                    sx={{ mr: 2 }}
                    value={newUserFirstName}
                    onChange={(e) => setNewUserFirstName(e.target.value)}
                    label={t("users.firstName")}
                    inputRef={newUserFirstNameField}
                />
            </TableCell>
            <TableCell>
                <TextField
                    variant="standard"
                    sx={{ mr: 2 }}
                    value={newUserLastName}
                    onChange={(e) => setNewUserLastName(e.target.value)}
                    label={t("users.lastName")}
                    inputRef={newUserLastNameField}
                />
            </TableCell>
            <TableCell colSpan={2} style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                    variant="standard"
                    sx={{ mr: 2 }}
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    label={t("users.email")}
                    error={errorOnNewUserEmailField()}
                />
            </TableCell>
            <TableCell>
                <div style={{ display: "inline-block", marginTop: "14px" }}>
                    <ManagerRole selectedValue={role} onchange={changeRole} />
                </div>
            </TableCell>
            <TableCell>
                <Tooltip title={getAddUserTooltip()}>
                    <span>
                        {" "}
                        {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                        <Button onClick={add} disabled={!canAddUser()}>
                            {t("users.add")}
                        </Button>
                    </span>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default AddUserAsManager;
