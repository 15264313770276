import { Effect, put as putEffect } from "redux-saga/effects";
import { ForceLogoutAction } from "../redux/auth/auth-actions";

export async function apiError(response: Response) {
    if (response.status === 400 || response.status === 404) {
        return await response.text().then((text: string) => {
            let error = "";
            if (text === "Email already exists" || text === "Already accepted") {
                //In these cases this error message is displayed
                error = "errors." + text; //to the user without a title
            } else {
                error = response.statusText.toUpperCase() + "\n" + text; //Otherwise includes the title
            }
            throw new Error(error);
        });
    }
    throw response;
}

export function* fetchError<TError>(
    error: any,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>,
) {
    if (error.status === 401) {
        yield putEffect(ForceLogoutAction());
    }
    let code: any = undefined;
    let message: string = "";
    if (error.statusText) {
        message = error.statusText;
        code = error.status;
    } else if (error.message) {
        message = error.message;
    } else {
        message = JSON.stringify(error);
    }
    yield onErr(message, code);
}
