import { all, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { convertApiCredentials, convertApiFetchResidents } from "../../api/api-conversions";
import {
    CreateNewResidentPasswordSuccessResponsePayload,
    FetchResidentsSuccessResponsePayload,
    UpsertResidentSuccessResponsePayload,
} from "../../api/api-types";
import { API_URL } from "../../constants/urls";
import selectIdToken from "../auth/select-id-token";
import { ReportErrorAction } from "../error/error-actions";
import {
    CreateNewResidentPasswordAction,
    CreateNewResidentPasswordFailureAction,
    CreateNewResidentPasswordSuccessAction,
    DeleteResidentAction,
    DeleteResidentFailureAction,
    DeleteResidentSuccessAction,
    DownloadResidentsListAction,
    DownloadResidentsListFailureAction,
    DownloadResidentsListSuccessAction,
    FetchResidentsAction,
    FetchResidentsFailureAction,
    FetchResidentsSuccessAction,
    FetchResidentsUnauthorizedAction,
    UploadResidentsAction,
    UploadResidentsFailureAction,
    UploadResidentsSuccessAction,
    UpsertResidentAction,
    UpsertResidentFailureAction,
    UpsertResidentSuccessAction,
} from "./residents-actions";

import {
    CreateNewResidentPasswordPayload,
    DeleteResidentPayload,
    DownloadResidentsListPayload,
    FetchResidentsPayload,
    Resident,
    UploadResidentsPayload,
    UpsertResidentPayload,
    UpsertResidents,
} from "./residents-types";

export function* fetchResidents(action: { type: string; payload: FetchResidentsPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/residents`,
        (resp: FetchResidentsSuccessResponsePayload) =>
            put(FetchResidentsSuccessAction(convertApiFetchResidents(resp.residentsByApartmentId))),
        (err, code) => {
            put(FetchResidentsFailureAction());
            if (code === 403) {
                return put(FetchResidentsUnauthorizedAction(err));
            } else {
                return put(ReportErrorAction(err));
            }
        },
    );
}

export function* upsertResident(action: { type: string; payload: UpsertResidentPayload }) {
    const companyId: number = action.payload.companyId;
    const upsertResidents: UpsertResidents = action.payload.toUpsertByApartmentId;

    yield api.put(
        `/companies/${companyId}/residents`,
        { toUpsertByApartmentId: upsertResidents },
        (res: UpsertResidentSuccessResponsePayload) =>
            all([
                put(
                    UpsertResidentSuccessAction({
                        residentsByApartmentId: convertApiFetchResidents(res.residentsByApartmentId),
                        newCredentialsByResidentId: convertApiCredentials(res.newCredentialsByResidentId),
                    }),
                ),
                put(FetchResidentsAction({ companyId: companyId })),
            ]),
        (err) => put(UpsertResidentFailureAction(err)),
    );
}

export function* upsertResidentFailure(_action: { type: string; payload: string }) {
    const err = "errors.residentsUpsertError";
    yield put(ReportErrorAction(err));
}

export function* deleteResident(action: { payload: DeleteResidentPayload }) {
    const companyId: number = action.payload.companyId;
    const residentId: number = action.payload.residentId;
    yield api.remove(
        `/companies/${companyId}/residents/${residentId}`,
        {},
        () => all([put(DeleteResidentSuccessAction()), put(FetchResidentsAction({ companyId: companyId }))]),
        (err) => all([put(DeleteResidentFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* createNewPasswordForResident(action: { payload: CreateNewResidentPasswordPayload }) {
    const companyId: number = action.payload.companyId;
    const residentId: number = action.payload.residentId;
    yield api.post(
        `/companies/${companyId}/residents/${residentId}/password`,
        { companyId, residentId },
        (resp: CreateNewResidentPasswordSuccessResponsePayload) =>
            all([
                put(CreateNewResidentPasswordSuccessAction({ [residentId]: resp })),
                put(FetchResidentsAction({ companyId: companyId })),
            ]),
        (err) => all([put(CreateNewResidentPasswordFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* downloadResidentsList(action: { payload: DownloadResidentsListPayload }) {
    const idToken: string = yield select(selectIdToken);
    const companyId: number = action.payload.companyId;

    yield api.download(
        `${API_URL}/companies/${companyId}/residents/csv`,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(DownloadResidentsListSuccessAction())],
        [put(DownloadResidentsListFailureAction())],
    );
}

export function* uploadResidents(action: { payload: UploadResidentsPayload }) {
    const idToken: string = yield select(selectIdToken);
    const companyId: number = action.payload.companyId;
    const residents: Resident[] = action.payload.residents;

    yield api.download(
        `${API_URL}/companies/${companyId}/residents`,
        "POST",
        { residents },
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(UploadResidentsSuccessAction()), put(FetchResidentsAction({ companyId }))],
        [put(UploadResidentsFailureAction())],
    );
}

export function* residentsSaga() {
    yield takeEvery(FetchResidentsAction, fetchResidents);
    yield takeEvery(UpsertResidentAction, upsertResident);
    yield takeEvery(DeleteResidentAction, deleteResident);
    yield takeEvery(UpsertResidentFailureAction, upsertResidentFailure);
    yield takeEvery(CreateNewResidentPasswordAction, createNewPasswordForResident);
    yield takeEvery(DownloadResidentsListAction, downloadResidentsList);
    yield takeEvery(UploadResidentsAction, uploadResidents);
}
