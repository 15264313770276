import { AnyAction } from "redux";
import { ContractsState } from "./contracts-types";
import {
    CreateUserDPARequestAction,
    CreateUserDPARequestFailureAction,
    CreateUserDPARequestSuccessAction,
    FetchUserContractsAction,
    FetchUserContractsFailureAction,
    FetchUserContractsSuccessAction,
    SetInviteeSignRequestSentByContractIdAction,
    SendInviteeSignRequestAction,
    SendInviteeSignRequestFailureAction,
    SendInviteeSignRequestSuccessAction,
    CreateCompanyDPARequestAction,
    CreateCompanyDPARequestSuccessAction,
    CreateCompanySubscriptionRequestAction,
    CreateCompanySubscriptionRequestFailureAction,
    CreateCompanyDPARequestFailureAction,
    CreateCompanySubscriptionRequestSuccessAction,
} from "./contracts-actions";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";

const initialState: ContractsState = {
    userContractsByUserId: {},
    loading: 0,
    inviteeSignRequestSentByContractId: {},
};

const actions = (state: ContractsState, action: AnyAction) => {
    if (FetchUserContractsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateUserDPARequestAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateCompanySubscriptionRequestAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateCompanyDPARequestAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendInviteeSignRequestAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SetInviteeSignRequestSentByContractIdAction.match(action)) {
        return {
            ...state,
            inviteeSignRequestSentByContractId: {
                ...state.inviteeSignRequestSentByContractId,
                [action.payload.contractId]: false,
            },
        };
    }

    return state;
};

const successActions = (state: ContractsState, action: AnyAction) => {
    if (FetchUserContractsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            userContractsByUserId: {
                ...state.userContractsByUserId,
                [action.payload.userId]: action.payload.contracts,
            },
        };
    }

    if (CreateUserDPARequestSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
        };
    }

    if (CreateCompanySubscriptionRequestSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
        };
    }

    if (CreateCompanyDPARequestSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
        };
    }

    if (SendInviteeSignRequestSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            inviteeSignRequestSentByContractId: {
                ...state.inviteeSignRequestSentByContractId,
                [action.payload.contractId]: true,
            },
        };
    }
    return state;
};

const failureActions = (state: ContractsState, action: AnyAction) => {
    if (FetchUserContractsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateUserDPARequestFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateCompanySubscriptionRequestFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }
    if (CreateCompanyDPARequestFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendInviteeSignRequestFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

export default function contractsReducer(state: ContractsState = initialState, action: AnyAction): ContractsState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);

    return state;
}
