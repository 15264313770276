import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import { ReportErrorAction } from "../../../redux/error/error-actions";
import { Company } from "../../../redux/companies/companies-types";
import { CompanyRole } from "../../../redux/auth/auth-types";
import { useTranslation } from "react-i18next";

export interface AuthorizedProps {
    isAuthenticated: boolean;
    isAdmin: boolean;
    requireIsAuthenticated: boolean;
    requireIsAdmin: boolean;
    requireIsPremium?: boolean;
    companyId: number;
    termsAndConditionsAccepted: boolean;
    userFetched: boolean;
    isInTermsAndConditionPage?: boolean;
    resettingPassword?: boolean;
}

interface ConnectedAuthorizedProps {
    requireIsAuthenticated: boolean;
    requireIsAdmin: boolean;
    requireIsPremium?: boolean;
    isInTermsAndConditionPage?: boolean;
    resettingPassword?: boolean;
}

interface RedirectProps {
    requireIsAuthenticated: boolean;
    isAuthenticated: boolean;
    requireIsAdmin: boolean;
    isAdmin: boolean;
    companyId: number;
    userFetched: boolean;
    termsAndConditionsAccepted: boolean;
    isInTermsAndConditionPage?: boolean;
    requireIsPremium?: boolean;
}

type RequirementsType =
    | "authenticatedness fails"
    | "terms and conditions not accepted"
    | "authenticatedness ok"
    | "unauthorized"
    | "contracts not signed"
    | "Premium not found";

type RequirementsCheckResult = (requirements: RequirementsType) => boolean;

function requirementsCheck(
    company: Company | undefined,
    companyRole: CompanyRole | undefined,
    props: RedirectProps,
): RequirementsCheckResult {
    const redirectToTermsAndConditionsModal = (): boolean =>
        !props.isAdmin &&
        props.userFetched &&
        !props.termsAndConditionsAccepted &&
        props.isAuthenticated &&
        !props.isInTermsAndConditionPage;
    const authenticatedButNoCompaniesFetched = (): boolean =>
        !company && props.isAuthenticated && props.termsAndConditionsAccepted;
    const requireIsPremiumButCompanyHasNoPremium = (): boolean => {
        if (company && props.requireIsPremium) {
            return props.requireIsPremium && !company.premium;
        }
        return false;
    };
    const companyContractsNotSigned = (): boolean => {
        if (companyRole) {
            return !props.isAdmin && !companyRole.requiredContractsSigned;
        }
        return false;
    };

    const premiumNotFound = (): boolean =>
        !authenticatedButNoCompaniesFetched() && requireIsPremiumButCompanyHasNoPremium();

    function requirementCase(requirements: RequirementsType): boolean {
        switch (requirements) {
            case "authenticatedness fails":
                return props.requireIsAuthenticated && !props.isAuthenticated;
            case "terms and conditions not accepted":
                return redirectToTermsAndConditionsModal();
            case "authenticatedness ok":
                return !props.requireIsAuthenticated && props.isAuthenticated;
            case "unauthorized":
                return props.requireIsAdmin && !props.isAdmin;
            case "contracts not signed":
                return companyContractsNotSigned();
            case "Premium not found":
                return premiumNotFound();
            default:
                return false;
        }
    }

    return requirementCase;
}

export function Authorized({ props, child }: { props: AuthorizedProps; child: JSX.Element }): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const company = useSelector(
        (state: RootState) => state.hydrolink.companies?.companies.find((c) => c.id === props.companyId),
    );
    const companyRole = useSelector((state: RootState) =>
        company ? state.hydrolink.auth.roleByCompanyId[company.id] : undefined,
    );

    const check = requirementsCheck(company, companyRole, {
        requireIsAuthenticated: props.requireIsAuthenticated,
        isAuthenticated: props.isAuthenticated,
        requireIsAdmin: props.requireIsAdmin,
        isAdmin: props.isAdmin,
        companyId: props.companyId,
        userFetched: props.userFetched,
        termsAndConditionsAccepted: props.termsAndConditionsAccepted,
        isInTermsAndConditionPage: props.isInTermsAndConditionPage,
        requireIsPremium: props.requireIsPremium,
    });

    if (!props.resettingPassword) {
        if (check("authenticatedness fails")) {
            return <Navigate replace to="/login" />;
        }

        if (check("terms and conditions not accepted")) {
            return <Navigate replace to="/terms-and-conditions" />;
        }

        if (check("unauthorized")) {
            dispatch(ReportErrorAction("errors.unauthorized"));
            return <Navigate replace to="/login" />;
        }

        if (check("authenticatedness ok") || check("contracts not signed")) {
            return <Navigate replace to="/companies" />;
        }

        if (check("Premium not found")) {
            const premiumRequired = t("errors.premiumRequired");
            dispatch(ReportErrorAction(premiumRequired));
            let path = "/companies";
            if (props.companyId !== 0) {
                path = `/companies/${props.companyId}/`;
            }
            return <Navigate replace to={path} />;
        }
    }
    return child;
}

export default function ConnectedAuthorized({
    props,
    child,
}: {
    props: ConnectedAuthorizedProps;
    child: JSX.Element;
}): JSX.Element {
    const { isAuthenticated, isAdmin, termsAndConditionsAccepted, email } = useSelector(
        (state: RootState) => state.hydrolink.auth,
    );
    const params = useParams();
    const userFetched = email !== "";
    const companyId = parseInt(params.companyId ?? "0");

    return (
        <Authorized
            props={{
                ...props,
                isAuthenticated,
                isAdmin,
                userFetched,
                termsAndConditionsAccepted,
                companyId,
            }}
            child={child}
        />
    );
}
