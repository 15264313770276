import { Theme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../../assets/App.css";
import "../../assets/index.css";
import "../../assets/Mobile.css";
import { HydrolinkStore } from "../../configureStore";
import Centers from "../../pages/centers";
import Companies from "../../pages/companies";
import CompanyTabs, { Tabs } from "../../pages/companies/CompanyTabs";
import Company from "../../pages/company";
import CurrentUserPasswordReset from "../../pages/current-user-password-reset";
import InitReport from "../../pages/initreport";
import Landing from "../../pages/landing";
import Login from "../../pages/login";
import Notes from "../../pages/notes";
import ConsumptionReport from "../../pages/reports";
import ResidentPasswordReset from "../../pages/resident-password-reset";
import ResidentsManagement from "../../pages/residents/ResidentsManagement";
import TermsAndConditions from "../../pages/terms-and-conditions";
import Users from "../../pages/users";
import Authorized from "../common/authorized/Authorized";
import ErrorToast from "../common/error-toast/ErrorToast";
import { elementWithHeaderAndFooter } from "../common/header/Header";
import Toasts from "../common/toast/Toasts";
import Spinner from "../Spinner";
import { ViewContainer } from "../ViewContainer";
import ViewTermsAndConditions from "../../pages/terms-and-conditions/ViewTermsAndConditions";
import Guides from "../../pages/guides/Guides";
import SmokeAlarmsView from "../../pages/smoke-alarms";
import AlarmTypes from "../../pages/alarms";
import CurrentUserContracts from "../../pages/current-user-contracts";

export const hydrolinkRoutes = [
    {
        path: "/login",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: false,
                    requireIsAdmin: false,
                }}
                child={<Login />}
            />
        ),
    },
    {
        path: "/password-reset/",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: false,
                    requireIsAdmin: false,
                }}
                child={<ResidentPasswordReset />}
            />
        ),
    },
    {
        path: "/password-reset/:token",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: false,
                    requireIsAdmin: false,
                }}
                child={<ResidentPasswordReset />}
            />
        ),
    },
    {
        path: "/current-user-password-reset/",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: false,
                    requireIsAdmin: false,
                    resettingPassword: true,
                }}
                child={<CurrentUserPasswordReset />}
            />
        ),
    },
    {
        path: "/current-user-contracts/",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<CurrentUserContracts />}
            />
        ),
    },
    {
        path: "/",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<Landing />}
            />
        ),
    },
    {
        path: "/terms-and-conditions",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                    isInTermsAndConditionPage: true,
                }}
                child={<TermsAndConditions />}
            />
        ),
    },
    {
        path: "/view-terms-and-conditions",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                    isInTermsAndConditionPage: true,
                }}
                child={<ViewTermsAndConditions />}
            />
        ),
    },
    {
        path: "/companies",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<Companies />}
            />
        ),
    },
    {
        path: "/companies/:companyId/",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<Company />}
            />
        ),
    },
    {
        path: "/companies/:companyId/reports",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={elementWithHeaderAndFooter(
                    <ViewContainer>
                        <CompanyTabs tab={Tabs.REPORTS} />
                        <ConsumptionReport />
                    </ViewContainer>,
                )}
            />
        ),
    },
    {
        path: "/companies/:companyId/initialization-report",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={elementWithHeaderAndFooter(
                    <ViewContainer>
                        <CompanyTabs tab={Tabs.INITIALIZATIONREPORT} />
                        <InitReport />
                    </ViewContainer>,
                )}
            />
        ),
    },
    {
        path: "/companies/:companyId/centers",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={elementWithHeaderAndFooter(
                    <ViewContainer>
                        <CompanyTabs tab={Tabs.CENTERS} />
                        <Centers />
                    </ViewContainer>,
                )}
            />
        ),
    },
    {
        path: "/companies/:companyId/notes",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={elementWithHeaderAndFooter(
                    <ViewContainer>
                        <CompanyTabs tab={Tabs.NOTES} />
                        <Notes />
                    </ViewContainer>,
                )}
            />
        ),
    },
    {
        path: "/companies/:companyId/residents",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                    requireIsPremium: true,
                }}
                child={<ResidentsManagement />}
            />
        ),
    },
    {
        path: "/companies/:companyId/smokeAlarms",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                    requireIsPremium: true,
                }}
                child={elementWithHeaderAndFooter(
                    <ViewContainer>
                        <CompanyTabs tab={Tabs.SMOKEALARMS} />
                        <SmokeAlarmsView />
                    </ViewContainer>,
                )}
            />
        ),
    },
    {
        path: "/guides",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<Guides />}
            />
        ),
    },
    {
        path: "/users",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: false,
                }}
                child={<Users />}
            />
        ),
    },
    {
        path: "/alarmTypes",
        element: (
            <Authorized
                props={{
                    requireIsAuthenticated: true,
                    requireIsAdmin: true,
                }}
                child={<AlarmTypes />}
            />
        ),
    },
];

export const browserRouter = createBrowserRouter(hydrolinkRoutes);

export default function Application({ store, theme }: { store: HydrolinkStore; theme: Theme }): JSX.Element {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <RouterProvider router={browserRouter} />
                <ErrorToast />
                <Toasts />
                <Spinner />
            </ThemeProvider>
        </Provider>
    );
}
