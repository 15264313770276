import { ReactElement } from "react";
import { Grid, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import HeaderBackground from "../../../assets/New_Header.svg";
import HydrolinkLogo from "../../../assets/New_Logo.svg";
import { useTranslation } from "react-i18next";
import HamburgerMenu from "../hamburger-menu/HamburgerMenu";
import LanguageSelector from "../language-selector/LanguageSelector";
import { logout } from "../../../redux/auth/auth";
import { colors, spacings, typography } from "../../../theme";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MissingContractsNotification from "../contracts/MissingContractsNotification";
import { useAppDispatch, useAppSelector } from "../../../utils/store-helpers";
import Footer from "../../footer/Footer";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";

type Props = {
    email: string;
    showUserUtils: boolean;
};

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
        autoFocus={false}
    />
))(() => ({
    "& .MuiPaper-root": {
        borderRadius: 3,
        border: `1px solid #BDBBBB`,
        marginTop: spacings.standardSpacing,
        minWidth: 180,
        color: colors.neutral08,
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "&:hover": {
                backgroundColor: "#EAEAEA",
            },
            "&:active": {
                backgroundColor: "#EAEAEA",
            },
        },
    },
}));

function AccountDropDown({ email }: { email: string }): JSX.Element {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const contractFeatureEnabled = useAppSelector((state) => state.hydrolink.auth.contractFeatureEnabled);

    const dispatch = useAppDispatch();

    const doLogout = () => {
        dispatch(logout());
    };

    const icon = {
        icon: open ? faSortUp : faSortDown,
        marginTop: open ? "7px" : "0px",
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const canManageContracts = useHasPrivileges("can manage user contracts").hasPrivilege;

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }} onClick={handleClick}>
                <Typography
                    style={{
                        color: colors.blue01,
                        fontSize: typography.fontSize.small,
                        marginRight: spacings.inlineSpacing,
                    }}
                >
                    {email}
                </Typography>
                <FontAwesomeIcon size="sm" style={{ marginTop: icon.marginTop }} color={"#4D4D4D"} icon={icon.icon} />
            </div>

            <StyledMenu id="account-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {" "}
                {contractFeatureEnabled && canManageContracts && (
                    <Link to={`/current-user-contracts/`} style={{ color: colors.neutral08 }}>
                        <MenuItem onClick={handleClose} disableRipple>
                            {t("landing.contracts")}
                        </MenuItem>
                    </Link>
                )}
                <Link to={`/current-user-password-reset/`} style={{ color: colors.neutral08 }}>
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("landing.changePassword")}
                    </MenuItem>
                </Link>
                <MenuItem onClick={doLogout} disableRipple>
                    {t("landing.logout")}
                </MenuItem>
            </StyledMenu>
        </>
    );
}

export function Header({ email, showUserUtils }: Props): JSX.Element {
    const headerBgStyle = {
        backgroundImage: `url(${HeaderBackground})`,
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "74px",
    };

    return (
        <>
            <div>
                <div style={{ margin: "0 auto", maxWidth: "1280px" }}>
                    <Grid container justifyContent={"space-between"} wrap={"nowrap"}>
                        {/* LEFT SIDE */}
                        <Grid
                            item
                            xs={10}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            {showUserUtils && <HamburgerMenu />}
                        </Grid>

                        {/* LOGO */}
                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Link to={"/"}>
                                <img
                                    src={HydrolinkLogo}
                                    alt="hydrolink-logo"
                                    style={{ width: "200px", margin: "0 auto" }}
                                />
                            </Link>
                        </Grid>

                        {/* RIGHT SIDE */}
                        <Grid item xs={10}>
                            <Grid
                                container
                                wrap={"nowrap"}
                                justifyContent={"flex-end"}
                                style={{ height: "100%", paddingTop: "1em" }}
                            >
                                {showUserUtils && (
                                    <>
                                        <Grid item style={{ padding: "7px 15px" }}>
                                            <AccountDropDown email={email} />
                                        </Grid>
                                    </>
                                )}

                                <Grid item>
                                    <LanguageSelector buttonClassName="language-select-button-header" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={headerBgStyle} />
            </div>
            <MissingContractsNotification />
        </>
    );
}

function ConnectedHeader({ showUserUtils }: { showUserUtils: boolean }): JSX.Element {
    const email = useSelector((state: RootState): string => state.hydrolink.auth.email);

    return <Header email={email} showUserUtils={showUserUtils} />;
}

// Maybe showUserUtils could have a better name? It's functionality is to hide the hamburger menu, email and logout button
export const elementWithHeaderAndFooter = (element: ReactElement, showUserUtils: boolean = true) => {
    return (
        <div style={{ position: "relative", minHeight: "100vh" }}>
            <ConnectedHeader showUserUtils={showUserUtils} />
            <div style={{ paddingBottom: "2.5rem" }}>{element}</div>
            <Footer />
        </div>
    );
};
