import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import {
    CloseCompanyBasicInfoModal,
    CreateCompanyAction,
    SaveCompanyBasicInfoAction,
} from "../../redux/companies/companies-actions";
import { Company, CreateCompanyPayload, SaveCompanyPayload } from "../../redux/companies/companies-types";
import { ReportErrorAction } from "../../redux/error/error-actions";
import { alignments } from "../../theme";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import { useNavigate } from "react-router-dom";

function GetDialogActions({
    company,
    companyData,
    onClose,
}: {
    company: Company | undefined;
    companyData: CreateCompanyPayload;
    onClose: () => void;
}) {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const saveBasicInfoButtonRights = useHasPrivileges(
        "can update basic company info",
        company && company.id,
    ).hasPrivilege;

    const onSave = () => {
        const regex = /^\s*$/;
        if (regex.test(companyData.name)) {
            dispatch(ReportErrorAction("errors.companyNameMissing"));
        } else {
            if (company) {
                const saveCompanyPayload: SaveCompanyPayload = {
                    ...companyData,
                    id: company.id,
                };
                dispatch(SaveCompanyBasicInfoAction(saveCompanyPayload));
            } else {
                dispatch(CreateCompanyAction(companyData));
            }
        }
    };

    return (
        <>
            <Button onClick={onClose}>{t("generic.cancel")}</Button>
            <Button disabled={!saveBasicInfoButtonRights} variant="contained" onClick={onSave}>
                {t("generic.save")}
            </Button>
        </>
    );
}

type Props = {
    isOpen: boolean;
    company?: Company | undefined;
};

export function CompanyBasicInfoModal({ isOpen, company }: Props) {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (company) {
            setCompanyData({
                ...company,
                navigate,
            });
        }
    }, [company, isOpen, navigate]);

    const [companyData, setCompanyData] = useState<CreateCompanyPayload>({
        name: company?.name ?? "",
        streetAddress: company?.streetAddress ?? "",
        zipCode: company?.zipCode ?? "",
        town: company?.town ?? "",
        navigate: navigate,
    });

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>, companyField: keyof typeof companyData) => {
        setCompanyData({
            ...companyData,
            [companyField]: e.target.value,
        });
    };

    const onClose = () => {
        dispatch(CloseCompanyBasicInfoModal());
    };

    // The reason as to why this is not a normal React component is because the input field unselects on every render
    function companyModalInput(field: keyof typeof companyData) {
        return (
            <ModalInput
                key={field}
                title={t("companies.create." + field)}
                tooltip={t("companies.create." + field + ".tooltip")}
                value={String(companyData[field])}
                handleFormValue={(e) => onFormChange(e, field)}
            />
        );
    }

    return (
        <Modal
            title={company ? t("companies.basicInfo") : t("companies.create.title")}
            open={isOpen}
            dialogActions={<GetDialogActions company={company} companyData={companyData} onClose={onClose} />}
            closeModal={onClose}
        >
            <div
                style={{
                    ...alignments.twoElementGrid,
                }}
            >
                {companyModalInput("name")}
                {companyModalInput("streetAddress")}
                {companyModalInput("zipCode")}
                {companyModalInput("town")}
            </div>
        </Modal>
    );
}
