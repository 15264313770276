import { all, put, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { convertApiClientCommand } from "../../api/api-conversions";
import { ApiClientCommand } from "../../api/api-types";
import { FetchApartmentsAction } from "../apartments/apartments-actions";
import { FetchCompaniesWithoutAlarmsAction } from "../companies/companies-actions";
import { ReportErrorAction } from "../error/error-actions";
import {
    FetchClientCommandsAction,
    FetchClientCommandsFailureAction,
    FetchClientCommandsSuccessAction,
    SendClientCommandAction,
    SendClientCommandFailureAction,
    SendClientCommandSuccessAction,
    UpdateCenterCodeAction,
    UpdateCenterCodeFailureAction,
    UpdateCenterCodeSuccessAction,
    UpdateClientTelephoneNumberAction,
    UpdateClientTelephoneNumberFailureAction,
    UpdateClientTelephoneNumberSuccessAction,
} from "./centers-actions";
import { SendClientCommandPayload, UpdateCenterCodePayload, UpdateClientTelephoneNumberPayload } from "./centers-types";

// Fetches translations and info for client commands, not client commands for a specific center
export function* fetchClientCommands(_action: { payload: void }) {
    yield api.get(
        `/client_commands`,
        (res: ApiClientCommand[]) => put(FetchClientCommandsSuccessAction(res.map(convertApiClientCommand))),
        (err) => all([put(FetchClientCommandsFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* postClientCommand(action: { payload: SendClientCommandPayload }) {
    const companyId: number = action.payload.companyId;
    const clientId: number = action.payload.clientId;
    const tag: string = action.payload.tag;

    yield api.post(
        `/clients/${clientId}/commands`,
        { tag: tag },
        () =>
            all([
                put(SendClientCommandSuccessAction()),
                put(FetchApartmentsAction({ companyId: companyId })),
                put(FetchCompaniesWithoutAlarmsAction()),
            ]),
        (err) => all([put(SendClientCommandFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* updateCenterCode(action: { payload: UpdateCenterCodePayload }) {
    yield api.put(
        `/clients/${action.payload.clientId}/code`,
        { newCode: action.payload.newCode },
        () =>
            all([
                put(UpdateCenterCodeSuccessAction(action.payload)),
                put(FetchApartmentsAction({ companyId: action.payload.companyId })),
            ]),
        (err) => all([put(UpdateCenterCodeFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* updateClientTelephoneNumber(action: { payload: UpdateClientTelephoneNumberPayload }) {
    const clientId: number = action.payload.clientId;
    const newTelephoneNumber: string | undefined = action.payload.newTelephoneNumber;

    yield api.put(
        `/clients/${clientId}/telephone_number`,
        { newTelephoneNumber: newTelephoneNumber },
        () =>
            all([
                put(FetchCompaniesWithoutAlarmsAction()),
                put(UpdateClientTelephoneNumberSuccessAction(action.payload)),
            ]),
        (err) => all([put(UpdateClientTelephoneNumberFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* centersSaga() {
    yield takeEvery(FetchClientCommandsAction, fetchClientCommands);
    yield takeEvery(SendClientCommandAction, postClientCommand);
    yield takeEvery(UpdateCenterCodeAction, updateCenterCode);
    yield takeEvery(UpdateClientTelephoneNumberAction, updateClientTelephoneNumber);
}
