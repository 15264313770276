import { Company } from "../../../../redux/companies/companies-types";
import ManagerRow from "./ManagerRow";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { getColor, NewUser } from "./Managers";
import { useTranslation } from "react-i18next";
import AddUserAsManager from "./AddUserAsManager";
import { CompanyUserWithState } from "./listEditor";

type Props = {
    company: Company;
    editedManagers: CompanyUserWithState[];
    addUser: (user: NewUser) => void;
    deleteManager: (managerId: number) => void;
};

const ManagersAndViewerViews = ({ company, editedManagers, addUser, deleteManager }: Props) => {
    const { t } = useTranslation();
    const isAllowedToAddUsers = useHasPrivileges("is allowed to add users to the company", company.id).hasPrivilege;

    return (
        <>
            {editedManagers.map((manager) => (
                <ManagerRow
                    key={manager.user.userId}
                    id={manager.user.userId}
                    firstName={manager.user.firstName}
                    lastName={manager.user.lastName}
                    color={getColor(manager.state)}
                    delete={deleteManager}
                    companyId={company.id}
                    email={manager.user.email}
                >
                    <>{manager.user.principal ? t(`roles.principal`) : t(`roles.${manager.user.role}`)}</>
                </ManagerRow>
            ))}
            {isAllowedToAddUsers && <AddUserAsManager company={company} addUser={addUser} />}
        </>
    );
};

export default ManagersAndViewerViews;
