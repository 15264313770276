import "./assets/index.css";
import { createRoot } from "react-dom/client";
import getStore from "./configureStore";
import { loadPersistedData } from "./redux/persistence";
import Application from "./components/application/Application";
import { FetchUserInformationAction } from "./redux/auth/auth-actions";
import { theme } from "./theme";
import { VERSION } from "./version";

console.info(`Version ${VERSION}`);

const store = getStore();

loadPersistedData(store);

if (store.getState().hydrolink.auth.isAuthenticated) {
    store.dispatch(FetchUserInformationAction());
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Application store={store} theme={theme} />);
