import { HydrolinkStore } from "../configureStore";
import { getToken } from "../utils/persistence-utils";
import { SetIdTokenAction } from "./auth/auth-actions";

export function loadPersistedData(store: HydrolinkStore): void {
    loadIdToken(store);
}

function loadIdToken(store: HydrolinkStore): void {
    const idToken = getToken();
    if (idToken) {
        store.dispatch(SetIdTokenAction(idToken));
    }
}
